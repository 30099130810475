import React from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // Import Tippy CSS for styling

const EmerModal = ({ isOpen, onClose, onSubmit, formData, handleChange }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed overflow-y-auto inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded shadow-lg w-1/2">
                <h2 className="text-xl font-bold mb-4">Emergency Contact Information</h2>
                <form onSubmit={onSubmit}>
                    <input type="hidden" name="employee_id" value={formData.employee_id} />

                    <Tippy content="Enter first name">
                        <input
                            type="text"
                            name="first_name"
                            placeholder="First Name"
                            value={formData.first_name}
                            onChange={handleChange}
                            required
                            className="border p-2 mb-2 w-full"
                        />
                    </Tippy>

                    <Tippy content="Enter last name">
                        <input
                            type="text"
                            name="last_name"
                            placeholder="Last Name"
                            value={formData.last_name}
                            onChange={handleChange}
                            required
                            className="border p-2 mb-2 w-full"
                        />
                    </Tippy>

                    <Tippy content="Enter home phone number">
                        <input
                            type="text"
                            name="home_phone"
                            placeholder="Home Phone"
                            value={formData.home_phone}
                            onChange={handleChange}
                            required
                            className="border p-2 mb-2 w-full"
                        />
                    </Tippy>

                    <Tippy content="Enter mobile phone number">
                        <input
                            type="text"
                            name="mobile_phone"
                            placeholder="Mobile Phone"
                            value={formData.mobile_phone}
                            onChange={handleChange}
                            required
                            className="border p-2 mb-2 w-full"
                        />
                    </Tippy>

                    <Tippy content="Enter work phone number">
                        <input
                            type="text"
                            name="work_phone"
                            placeholder="Work Phone"
                            value={formData.work_phone}
                            onChange={handleChange}
                            required
                            className="border p-2 mb-2 w-full"
                        />
                    </Tippy>

                    <Tippy content="Enter first emergency contact number">
                        <input
                            type="text"
                            name="emergency_no_1"
                            placeholder="Emergency Contact 1"
                            value={formData.emergency_no_1}
                            onChange={handleChange}
                            required
                            className="border p-2 mb-2 w-full"
                        />
                    </Tippy>

                    <Tippy content="Enter second emergency contact number">
                        <input
                            type="text"
                            name="emergency_no_2"
                            placeholder="Emergency Contact 2"
                            value={formData.emergency_no_2}
                            onChange={handleChange}
                            required
                            className="border p-2 mb-2 w-full"
                        />
                    </Tippy>

                    <Tippy content="Enter relationship status">
                        <input
                            type="text"
                            name="relationship_status"
                            placeholder="Relationship Status"
                            value={formData.relationship_status}
                            onChange={handleChange}
                            required
                            className="border p-2 mb-2 w-full"
                        />
                    </Tippy>

                    <Tippy content="Enter address">
                        <input
                            type="text"
                            name="address"
                            placeholder="Address"
                            value={formData.address}
                            onChange={handleChange}
                            required
                            className="border p-2 mb-2 w-full"
                        />
                    </Tippy>

                    <Tippy content="Enter country of residence">
                        <input
                            type="text"
                            name="country"
                            placeholder="Country"
                            value={formData.country}
                            onChange={handleChange}
                            required
                            className="border p-2 mb-2 w-full"
                        />
                    </Tippy>

                    <div className="flex justify-between mt-4">
                        <button
                            type="button"
                            onClick={onClose}
                            className="bg-gray-300 text-black p-2 rounded hover:bg-gray-400"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600"
                        >
                            {formData.id ? 'Update Contact' : 'Add Contact'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EmerModal;
