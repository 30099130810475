import React from 'react';
import dia from '../assets/dia.svg';
import pro from '../assets/pro.svg';
import right from '../assets/right.svg';
import { useNavigate } from 'react-router-dom';

const Pricing = () => {
  const navigate = useNavigate()
const handleClick = () =>{
  navigate('/register')
}
  return (
    <section id="pricing" className=' mt-14 font-poppins'>
      <div className="font-poppins">
        <h1 data-aos="fade-up"  data-aos-duration="1000" className="text-lg text-center">Simple Licensing at</h1>
        <h1 data-aos="fade-up"  data-aos-duration="1000" className="text-3xl font-bold mt-4 text-center">Pricing Plans</h1>
        <div data-aos="fade-up"  data-aos-duration="1000" className="border-b-4 border-yellow-500 w-36 mx-auto mt-4"></div>
        <p className="24 p-2 text-yellow-500 rounded-xl shadow-lg mt-10 bg-yellow-400 border text-center mx-auto max-w-2xl">
          Genix has plans, from startup to enterprise, that scale with your needs. Subscribe to a plan that fits the size of your business.
        </p>
        <button className="h-10 p-2 mt-4 text-yellow-500 rounded-xl shadow-lg bg-yellow-400 border mx-auto block">
          Monthly Plans
        </button>
      </div>

      {/* 3 Column Grid for Pricing Plans */}
      <div data-aos="fade-up"  data-aos-duration="1000" className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 px-6 lg:px-14">
        {/* Plan Card */}
        <div className="bg-yellow-100 p-6 shadow-lg border rounded-2xl">
          <img src={dia} alt="diamond" className="w-12 mb-4" />
          <div className="flex items-center">
            <p className="mr-2">Basic Plan</p>
            <button className="flex items-center gap-2">
              <img src={pro} alt="pro plan" className="w-4 h-4" />
              <span>5 Users</span>
            </button>
          </div>
          <h1 className="text-2xl font-bold mt-2">£25.00</h1>
          <p>Per month</p>
          <div className="mt-4 flex flex-col">
            <div className="flex items-center gap-2">
              <img src={right} alt="checkmark" className="w-4 h-4" />
              <p>Unlimited File Uploads</p>
            </div>
            <div className="flex items-center gap-2">
              <img src={right} alt="checkmark" className="w-4 h-4" />
              <p>All basic modules</p>
            </div>
            <div className="flex items-center gap-2">
              <img src={right} alt="checkmark" className="w-4 h-4" />
              <p>All basic modules</p>
            </div>
          </div>
          <button onClick={handleClick} className="mt-6 px-4  w-32 py-2 bg-yellow-300 text-white font-semibold rounded-xl shadow-lg">
            Get Started
          </button>
        </div>

        <div className="bg-yellow-100 p-6 shadow-lg border rounded-xl">
          <img src={dia} alt="diamond" className="w-12 mb-4" />
          <div className="flex items-center">
            <p className="mr-2">Standard Plan</p>
            <button className="flex items-center gap-2">
              <img src={pro} alt="pro plan" className="w-4 h-4" />
              <span>10 Users</span>
            </button>
          </div>
          <h1 className="text-2xl font-bold mt-2">£50.00</h1>
          <p>Per month</p>
          <div className="mt-4 flex flex-col">
            <div className="flex items-center gap-2">
              <img src={right} alt="checkmark" className="w-4 h-4" />
              <p>Unlimited File Uploads</p>
            </div>
            <div className="flex items-center gap-2">
              <img src={right} alt="checkmark" className="w-4 h-4" />
              <p>All basic modules</p>
            </div>
            <div className="flex items-center gap-2">
              <img src={right} alt="checkmark" className="w-4 h-4" />
              <p>Instant Chat Support</p>
            </div>
          </div>
          <button onClick={handleClick} className="mt-6  w-32 px-4 py-2 bg-yellow-300 text-white font-semibold rounded-xl shadow-lg">
            Get Started
          </button>
        </div>

        <div data-aos="fade-up"  data-aos-duration="1000" className="bg-yellow-100 border p-6 shadow-lg rounded-xl">
          <img src={dia} alt="diamond" className="w-12 mb-4" />
          <div className="flex items-center">
            <p className="mr-2">Enterprise Plan</p>
            <button className="flex items-center gap-2">
              <img src={pro} alt="pro plan" className="w-4 h-4" />
              <span>20 Users</span>
            </button>
          </div>
          <h1 className="text-2xl font-bold mt-2">£75.00</h1>
          <p>Per month</p>
          <div className="mt-4 flex flex-col">
            <div className="flex items-center gap-2">
              <img src={right} alt="checkmark" className="w-4 h-4" />
              <p>Unlimited File Uploads</p>
            </div>
            <div className="flex items-center gap-2">
              <img src={right} alt="checkmark" className="w-4 h-4" />
              <p>All basic modules</p>
            </div>
            <div className="flex items-center gap-2">
              <img src={right} alt="checkmark" className="w-4 h-4" />
              <p>Instant Chat Support</p>
            </div>
          </div>
          <button  onClick={handleClick} className="mt-6 w-32 px-4   py-2 bg-yellow-300 text-white font-semibold rounded-xl shadow-lg">
            Get Started
          </button>
        </div>
      </div>
	  <button className="h-10 p-2 mt-4 text-yellow-500 rounded-xl shadow-lg bg-yellow-400 border mx-auto block">
          Yearly Plans
        </button>
		<div data-aos="fade-up"  data-aos-duration="1000" className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 px-6 lg:px-14">
        {/* Plan Card */}
        <div className="bg-yellow-100 p-6 shadow-lg border rounded-2xl">
          <img src={dia} alt="diamond" className="w-12 mb-4" />
          <div className="flex items-center">
            <p className="mr-2">Basic Plan</p>
            <button className="flex items-center gap-2">
              <img src={pro} alt="pro plan" className="w-4 h-4" />
              <span>5 Users</span>
            </button>
          </div>
          <h1 className="text-2xl font-bold mt-2">£250</h1>
          <p>Per month</p>
          <div className="mt-4 flex flex-col">
            <div className="flex items-center gap-2">
              <img src={right} alt="checkmark" className="w-4 h-4" />
              <p>Unlimited File Uploads</p>
            </div>
            <div className="flex items-center gap-2">
              <img src={right} alt="checkmark" className="w-4 h-4" />
              <p>All basic modules</p>
            </div>
            <div className="flex items-center gap-2">
              <img src={right} alt="checkmark" className="w-4 h-4" />
              <p>All basic modules</p>
            </div>
          </div>
          <button onClick={handleClick} className="mt-6 px-4  w-32 py-2 bg-yellow-300 text-white font-semibold rounded-xl shadow-lg">
            Get Started
          </button>
        </div>

        <div data-aos="fade-up"  data-aos-duration="1000" className="bg-yellow-100 p-6 shadow-lg border rounded-xl">
          <img src={dia} alt="diamond" className="w-12 mb-4" />
          <div className="flex items-center">
            <p className="mr-2">Standard Plan</p>
            <button className="flex items-center gap-2">
              <img src={pro} alt="pro plan" className="w-4 h-4" />
              <span>10 Users</span>
            </button>
          </div>
          <h1 className="text-2xl font-bold mt-2">£500</h1>
          <p>Per month</p>
          <div className="mt-4 flex flex-col">
            <div className="flex items-center gap-2">
              <img src={right} alt="checkmark" className="w-4 h-4" />
              <p>Unlimited File Uploads</p>
            </div>
            <div className="flex items-center gap-2">
              <img src={right} alt="checkmark" className="w-4 h-4" />
              <p>All basic modules</p>
            </div>
            <div className="flex items-center gap-2">
              <img src={right} alt="checkmark" className="w-4 h-4" />
              <p>Instant Chat Support</p>
            </div>
          </div>
          <button onClick={handleClick} className="mt-6  w-32 px-4 py-2 bg-yellow-300 text-white font-semibold rounded-xl shadow-lg">
            Get Started
          </button>
        </div>

        <div data-aos="fade-up"  data-aos-duration="1000" className="bg-yellow-100 border p-6 shadow-lg rounded-xl">
          <img src={dia} alt="diamond" className="w-12 mb-4" />
          <div className="flex items-center">
            <p className="mr-2">Enterprise Plan</p>
            <button className="flex items-center gap-2">
              <img src={pro} alt="pro plan" className="w-4 h-4" />
              <span>50 Users</span>
            </button>
          </div>
          <h1 className="text-2xl font-bold mt-2">£750</h1>
          <p>Per month</p>
          <div data-aos="fade-up"  data-aos-duration="1000" className="mt-4 flex flex-col">
            <div className="flex items-center gap-2">
              <img src={right} alt="checkmark" className="w-4 h-4" />
              <p>Unlimited File Uploads</p>
            </div>
            <div className="flex items-center gap-2">
              <img src={right} alt="checkmark" className="w-4 h-4" />
              <p>All basic modules</p>
            </div>
            <div className="flex items-center gap-2">
              <img src={right} alt="checkmark" className="w-4 h-4" />
              <p>Instant Chat Support</p>
            </div>
          </div>
          <button className="mt-6 w-32 px-4   py-2 bg-yellow-300 text-white font-semibold rounded-xl shadow-lg">
            Get Started
          </button>
        </div>
      </div>
    </section>
  );
};

export default Pricing;
