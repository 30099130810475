import React from 'react';

const PayrollModal = ({ isOpen, onClose, onSubmit, formData, handleChange }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed overflow-y-auto inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded shadow-lg w-1/2">
                <h2 className="text-xl font-bold mb-4">Payroll information</h2>
                <form onSubmit={onSubmit}>
                    <input type="hidden" name="employee_id" value={formData.employee_id} />
                    
                    <input
                        type="text"
                        name="national_insurance_number"
                        placeholder="National Insurance Number"
                        value={formData.national_insurance_number}
                        onChange={handleChange}
                        required
                        className="border p-2 mb-2 w-full"
                    />

                    <input
                        type="text"
                        name="passport_number"
                        placeholder="Passport Number"
                        value={formData.passport_number}
                        onChange={handleChange}
                        required
                        className="border p-2 mb-2 w-full"
                    />

                    <input
                        type="text"
                        name="passport_expiration_datey"
                        placeholder="Passport Expiration Date"
                        value={formData.passport_expiration_date}
                        onChange={handleChange}
                        required
                        className="border p-2 mb-2 w-full"
                    />

                    <input
                        type="text"
                        name="visa_number"
                        placeholder="Visa Number"
                        value={formData.visa_number}
                        onChange={handleChange}
                        required
                        className="border p-2 mb-2 w-full"
                    />
                     <input
                        type="text"
                        name="visa_expiration_date"
                        placeholder="Visa Expiration Date"
                        value={formData.visa_expiration_date}
                        onChange={handleChange}
                        required
                        className="border p-2 mb-2 w-full"
                    />
                     <input
                        type="text"
                        name="last_update_date"
                        placeholder="Last Update Date"
                        value={formData.last_update_date}
                        onChange={handleChange}
                        required
                        className="border p-2 mb-2 w-full"
                    />

                    

                    <div className="flex justify-between mt-4">
                        <button
                            type="button"
                            onClick={onClose}
                            className="bg-gray-300 text-black p-2 rounded hover:bg-gray-400"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600"
                        >
                            {formData.id ? 'Update Contract' : 'Add Contract'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default PayrollModal;
