import React from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // Import Tippy CSS for styling

const PerModal = ({ isOpen, onClose, onSubmit, formData, handleChange }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed overflow-y-auto inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded shadow-lg w-1/2">
                <h2 className="text-xl font-bold mb-4">Contract and Annual Leave Information</h2>
                <form onSubmit={onSubmit}>
                    <input type="hidden" name="employee_id" value={formData.employee_id} />

                    <Tippy content="Enter bank number">
                        <input
                            type="text"
                            name="bank_number"
                            placeholder="Bank Number"
                            value={formData.bank_number}
                            onChange={handleChange}
                            required
                            className="border p-2 mb-2 w-full"
                        />
                    </Tippy>

                    <Tippy content="Enter sort code">
                        <input
                            type="text"
                            name="sort_code"
                            placeholder="Sort Code"
                            value={formData.sort_code}
                            onChange={handleChange}
                            required
                            className="border p-2 mb-2 w-full"
                        />
                    </Tippy>

                    <Tippy content="Enter first name">
                        <input
                            type="text"
                            name="first_name"
                            placeholder="First Name"
                            value={formData.first_name}
                            onChange={handleChange}
                            required
                            className="border p-2 mb-2 w-full"
                        />
                    </Tippy>

                    <Tippy content="Enter middle name">
                        <input
                            type="text"
                            name="middle_name"
                            placeholder="Middle Name"
                            value={formData.middle_name}
                            onChange={handleChange}
                            className="border p-2 mb-2 w-full"
                        />
                    </Tippy>

                    <Tippy content="Enter last name">
                        <input
                            type="text"
                            name="last_name"
                            placeholder="Last Name"
                            value={formData.last_name}
                            onChange={handleChange}
                            required
                            className="border p-2 mb-2 w-full"
                        />
                    </Tippy>

                    <Tippy content="Enter date of birth">
                        <input
                            type="text"
                            name="dob"
                            placeholder="Date of Birth"
                            value={formData.dob}
                            onChange={handleChange}
                            required
                            className="border p-2 mb-2 w-full"
                        />
                    </Tippy>

                    <Tippy content="Enter nationality">
                        <input
                            type="text"
                            name="nationality"
                            placeholder="Nationality"
                            value={formData.nationality}
                            onChange={handleChange}
                            required
                            className="border p-2 mb-2 w-full"
                        />
                    </Tippy>

                    <Tippy content="Enter personal email">
                        <input
                            type="email"
                            name="personal_email"
                            placeholder="Personal Email"
                            value={formData.personal_email}
                            onChange={handleChange}
                            required
                            className="border p-2 mb-2 w-full"
                        />
                    </Tippy>

                    <Tippy content="Enter work email">
                        <input
                            type="email"
                            name="work_email"
                            placeholder="Work Email"
                            value={formData.work_email}
                            onChange={handleChange}
                            required
                            className="border p-2 mb-2 w-full"
                        />
                    </Tippy>

                    <Tippy content="Enter personal phone">
                        <input
                            type="text"
                            name="personal_phone"
                            placeholder="Personal Phone"
                            value={formData.personal_phone}
                            onChange={handleChange}
                            required
                            className="border p-2 mb-2 w-full"
                        />
                    </Tippy>

                    <Tippy content="Enter home phone">
                        <input
                            type="text"
                            name="home_phone"
                            placeholder="Home Phone"
                            value={formData.home_phone}
                            onChange={handleChange}
                            className="border p-2 mb-2 w-full"
                        />
                    </Tippy>

                    <div className="flex justify-between mt-4">
                        <button
                            type="button"
                            onClick={onClose}
                            className="bg-gray-300 text-black p-2 rounded hover:bg-gray-400"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600"
                        >
                            {formData.id ? 'Update Contract' : 'Add Contract'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default PerModal;
