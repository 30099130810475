import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { IoEyeOutline } from "react-icons/io5";


const EmployeeManagement = () => {
    const [employees, setEmployees] = useState([]);
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone1: '',
        phone2: '',
        gender: '',
        department: '',
        role: '',
        password: '',
        id: null,
        profile_pic: null,
    });
    
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setLoaded(true);
    }, []);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.company) {
            fetchEmployees(user);
        }
    }, []);

    const fetchEmployees = async (user) => {
        setLoading(true);
        setError(null);
        try {
            let url = `https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/read_users.php?company=${user.company}`;
            // If user is not HR, fetch only their profile
            if (user.role !== 'hr') {
                url += `&id=${user.id}`;
            }
            const response = await axios.get(url);
    
            // Check the response status and data
            if (response.data && response.data.status === 'error') {
                // If there's an error in the response, set the error message
                setError(response.data.message || 'Unexpected error occurred.');
            } else {
                // If the response does not indicate an error, handle the data
                const employeeData = Array.isArray(response.data) ? response.data : [response.data];
                setEmployees(user.role === 'hr' ? employeeData : [employeeData[0]]); // If HR, set all employees; otherwise set only their profile
            }
        } catch (err) {
            setError('Failed to fetch employees. Please try again.');
        } finally {
            setLoading(false);
        }
    };
    

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: files ? files[0] : value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const user = JSON.parse(localStorage.getItem('user'));
        setLoading(true);
        setError(null);

        const data = new FormData();
        Object.keys(formData).forEach((key) => {
            if (!(formData.id && key === 'password' && formData.password === '')) {
                data.append(key, formData[key]);
            }
        });
        data.append('company', user.company);
        data.append('plan', user.plan || "");

        try {
            formData.id ? await updateEmployee(data) : await addEmployee(data);
            fetchEmployees(user);
            resetForm();
            setIsModalOpen(false);
        } catch (err) {
            setError('Failed to save employee. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const addEmployee = async (data) => {
        await axios.post('https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/create_user.php', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    };

    const updateEmployee = async (data) => {
        const response = await axios.put('https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/update_user.php', data);
        if (response.data.status !== "success") {
            window.alert(response.data.message);
        }
    };

    const handleEdit = (employee) => {
        setFormData({ ...employee, id: employee.id });
        setIsModalOpen(true);
    };

    const handleDelete = async (id) => {
        const user = JSON.parse(localStorage.getItem('user'));
        setLoading(true);
        setError(null);
        try {
            await axios.delete('https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/delete_user.php', {
                data: { id, company: user.company },
            });
            fetchEmployees(user);
        } catch (err) {
            setError('Failed to delete employee. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleProfileClick = (id) => {
        navigate(`/employee/${id}`);
    };
    const user = JSON.parse(localStorage.getItem('user'))

    const resetForm = () => {
        setFormData({
            first_name: '',
            last_name: '',
            email: '',
            phone1: '',
            phone2: '',
            gender: '',
            department: '',
            role: '',
            password: '',
            id: null,
            profile_pic: null,
        });
    };

    const groupEmployeesByDepartment = () => {
        return employees.reduce((acc, employee) => {
            const { department } = employee;
            if (!acc[department]) {
                acc[department] = [];
            }
            acc[department].push(employee);
            return acc;
        }, {});
    };
    const plan = user.plan || ""

    const groupedEmployees = groupEmployeesByDepartment();

    return (
        <>
            <style jsx>{`
                @keyframes fadeIn {
                    from {
                        opacity: 0;
                        transform: translateY(20px);
                    }
                    to {
                        opacity: 1;
                        transform: translateY(0);
                    }
                }

                .fade-in {
                    animation: fadeIn 0.8s ease-in-out forwards;
                }
                    #text{
                     	 text-shadow: 1px 1px gray;}
            `}</style>
            <div className="p-8 font-poppins ">
            
                <h1 id='text' className='font-poppins text-5xl text-center  drop-shadow-lg font-bold mb-6 tracking-wide'>Employee Management</h1>
                {user.role==='hr' && (
                <button
                    className="bg-blue-500 font-poppins text-white px-4 py-2 rounded mb-4"
                    onClick={() => { setIsModalOpen(true); resetForm(); }}
                >
                    Add Employee
                </button>)}

                {error && <p className="text-red-500">{error}</p>}
                {loading && <p>Loading...</p>}

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6 gap-x-12">
            {Object.keys(groupedEmployees).map((department) => (
                <div key={department} className="rounded-lg p-4">
                    <div className="flex justify-between items-center mb-7 w-72">
                        <h2 className="text-xl font-semibold capitalize">{department}</h2>
                        <span className="bg-green-500 text-white rounded-full px-2">
                            {groupedEmployees[department].length}
                        </span>
                    </div>
                    <ul>
                        {groupedEmployees[department].map((emp) => (
                            <li key={emp.id} className="cursor-pointer flex rounded-2xl bg-blue-100 mt-5 w-full lg:w-80 p-5 lg:p-7 h-auto lg:h-36 shadow-lg">
                                <div className={`fade-in ${loaded ? 'active' : ''}`}>
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center">
                                            <div className="bg-gray-200 rounded-full h-16 w-16 flex justify-center text-xl items-center align-middle">
                                                {emp.profile_pic === null ? (
                                                    <span className="rounded-full">{emp.first_name.charAt(0)}{emp.last_name.charAt(0)}</span>
                                                ) : (
                                                    <img src={emp.profile_pic} alt="" className="rounded-full h-16 w-16" />
                                                )}
                                            </div>
                                            <div>
                                                <h3 className="font-semibold pl-7">{emp.first_name} {emp.last_name}</h3>
                                                <button
                                                    className="flex align-middle items-center space-x-4 gap-3 text-green-500 hover:underline pl-7"
                                                    onClick={() => handleProfileClick(emp.id)}
                                                >
                                                    <IoEyeOutline />
                                                    View full profile
                                                </button>
                                                <div className="flex pl-7">
                                                    <button
                                                        className="text-blue-500 hover:underline mr-3 block"
                                                        onClick={() => handleEdit(emp)}
                                                    >
                                                        Edit
                                                    </button>
                                                    <button
                                                        className="text-red-500 hover:underline"
                                                        onClick={() => handleDelete(emp.id)}
                                                    >
                                                        Delete
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            ))}
        </div>            </div>

            {isModalOpen && (
    <div className="fixed inset-0 pt-[450px] flex items-center justify-center z-50 bg-black bg-opacity-50 overflow-y-auto">
        <div className="bg-white rounded-lg shadow-xl p-8 w-full max-w-lg transition-transform transform scale-100">
            <div className="flex justify-between items-center mb-6">
                <h2 className="text-3xl font-bold text-gray-900">{formData.id ? 'Edit Employee' : 'Add Employee'}</h2>
                <button 
                    onClick={() => setIsModalOpen(false)} 
                    className="text-gray-600 hover:text-red-600 transition duration-200"
                    aria-label="Close Modal"
                >
                    &times; {/* Close icon */}
                </button>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label className="block text-gray-800 font-medium mb-2" htmlFor="first_name">First Name</label>
                    <input
                        type="text"
                        name="first_name"
                        value={formData.first_name}
                        onChange={handleChange}
                        required
                        className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-800 font-medium mb-2" htmlFor="last_name">Last Name</label>
                    <input
                        type="text"
                        name="last_name"
                        value={formData.last_name}
                        onChange={handleChange}
                        required
                        className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-800 font-medium mb-2" htmlFor="email">Email</label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                        className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-800 font-medium mb-2" htmlFor="phone1">Phone 1</label>
                    <input
                        type="text"
                        name="phone1"
                        value={formData.phone1}
                        onChange={handleChange}
                        required
                        className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-800 font-medium mb-2" htmlFor="phone2">Phone 2</label>
                    <input
                        type="text"
                        name="phone2"
                        value={formData.phone2}
                        onChange={handleChange}
                        className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-800 font-medium mb-2" htmlFor="gender">Gender</label>
                    <select
                        name="gender"
                        value={formData.gender}
                        onChange={handleChange}
                        required
                        className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                        <option value="">Select Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                    </select>
                </div>
                <div className="mb-4">
                    <label className="block text-gray-800 font-medium mb-2" htmlFor="department">Department</label>
                    <input
                        type="text"
                        name="department"
                        value={formData.department}
                        onChange={handleChange}
                        required
                        className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-800 font-medium mb-2" htmlFor="role">Role</label>
                    <input
                        type="text"
                        name="role"
                        value={formData.role}
                        onChange={handleChange}
                        required
                        className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-800 font-medium mb-2" htmlFor="password">Password</label>
                    <input
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        required={!formData.id}
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-800 font-medium mb-2" htmlFor="profile_pic">Profile Picture</label>
                    <input
                        type="file"
                        name="profile_pic"
                        onChange={handleChange}
                        className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>
                <input type="plan" value={plan} hidden />
                <button
                    type="submit"
                    className="bg-blue-600 text-white px-4 py-2 rounded-lg transition duration-200 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-300"
                >
                    {formData.id ? 'Update Employee' : 'Add Employee'}
                </button>
            </form>
        </div>
    </div>
)}

        </>
    );
};

export default EmployeeManagement;
