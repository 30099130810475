import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
const navigate = useNavigate();
    const handleForgotPassword = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setMessage('');

        // Replace with actual backend endpoint for OTP
        const apiEndpoint = 'https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/forgot.php';

        try {
            const response = await fetch(apiEndpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });
            const data = await response.json();

            if (data.status === 'success') {
                setOtpSent(true);
                setMessage('OTP has been sent to your email.');
            } else {
                setError(data.message || 'Failed to send OTP');
            }
        } catch (err) {
            setError('An error occurred. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleResetPassword = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setMessage('');

        // Replace with actual backend endpoint for password reset
        const resetApiEndpoint = 'https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/reset.php';

        try {
            const response = await fetch(resetApiEndpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, otp, new_password: newPassword }),
            });
            const data = await response.json();

            if (data.status === 'success') {
                setMessage('Password has been reset successfully.');
				navigate('/login')
            } else {
                setError(data.message || 'Failed to reset password.');
            }
        } catch (err) {
            setError('An error occurred. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="bg-white shadow-md rounded-lg p-8 max-w-md w-full">
                <h2 className="text-2xl font-semibold text-gray-800 text-center mb-6">Forgot Password</h2>
                {error && (
                    <p className="text-red-500 text-sm text-center mb-4">{error}</p>
                )}
                {message && (
                    <p className="text-green-500 text-sm text-center mb-4">{message}</p>
                )}
                {!otpSent ? (
                    <form onSubmit={handleForgotPassword} className="space-y-6">
                        <div>
                            <label className="block text-gray-600 font-medium mb-2">Email</label>
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="w-full border border-gray-300 rounded-lg px-3 py-2 focus:ring focus:ring-blue-200 focus:outline-none"
                                required
                                placeholder="Enter your email"
                            />
                        </div>
                        <button
                            type="submit"
                            className={`w-full text-white font-semibold py-2 rounded-lg transition-colors ${
                                loading
                                    ? 'bg-blue-400 cursor-not-allowed'
                                    : 'bg-blue-500 hover:bg-blue-600'
                            }`}
                            disabled={loading}
                        >
                            {loading ? 'Sending OTP...' : 'Send OTP'}
                        </button>
                    </form>
                ) : (
                    <form onSubmit={handleResetPassword} className="space-y-6 mt-4">
                        <div>
                            <label className="block text-gray-600 font-medium mb-2">OTP</label>
                            <input
                                type="text"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                                className="w-full border border-gray-300 rounded-lg px-3 py-2 focus:ring focus:ring-blue-200 focus:outline-none"
                                required
                                placeholder="Enter the OTP"
                            />
                        </div>
                        <div>
                            <label className="block text-gray-600 font-medium mb-2">New Password</label>
                            <input
                                type="password"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                className="w-full border border-gray-300 rounded-lg px-3 py-2 focus:ring focus:ring-blue-200 focus:outline-none"
                                required
                                placeholder="Enter your new password"
                            />
                        </div>
                        <button
                            type="submit"
                            className={`w-full text-white font-semibold py-2 rounded-lg transition-colors ${
                                loading
                                    ? 'bg-blue-400 cursor-not-allowed'
                                    : 'bg-blue-500 hover:bg-blue-600'
                            }`}
                            disabled={loading}
                        >
                            {loading ? 'Resetting Password...' : 'Reset Password'}
                        </button>
                    </form>
                )}
            </div>
        </div>
    );
};

export default ForgotPassword;
