import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Pie } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { FaFilePdf } from "react-icons/fa6";
Chart.register(ArcElement, Tooltip, Legend);

const Em = () => {
  const [name, setName] = useState("");
  const [payRate, setPayRate] = useState(0);
  const [payMethod, setPayMethod] = useState("Direct deposit");
  const [employees, setEmployees] = useState([]);
  const [company, setCompany] = useState("");
  const [editingEmployee, setEditingEmployee] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isChartModalOpen, setIsChartModalOpen] = useState(false);
  const [userSuggestions, setUserSuggestions] = useState([]);
  const [payrollData, setPayrollData] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const COLORS = ["#007bff", "#ffc107", "#fd7e14", "#28a745", "#17a2b8", "#6f42c1", "#e83e8c"];

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    const savedCompany = user?.company;
    if (savedCompany) {
      setCompany(savedCompany);
      fetchEmployees(savedCompany);
      fetchPayrollData(savedCompany);
    } else {
      alert("Company not set in localStorage!");
    }
  }, []);

  const fetchEmployees = async (companyName) => {
    try {
      const response = await axios.get(`https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/payroll_manage/read.php?company=${companyName}`);
      setEmployees(response.data || []);
    } catch (error) {
      console.error("Error fetching employees:", error);
      setEmployees([]);
    }
  };

  const fetchPayrollData = async (companyName) => {
    try {
      const response = await axios.get(`https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/payroll_manage/payroll_summary.php?company=${companyName}`);
      setPayrollData(response.data);
    } catch (error) {
      console.error("Error fetching payroll data:", error);
    }
  };

  const fetchUserSuggestions = async (input) => {
    try {
      const response = await axios.get(`https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/payroll_manage/users.php?company=${company}&query=${input}`);
      const suggestions = Array.isArray(response.data) ? response.data : [];
      setUserSuggestions(suggestions);
    } catch (error) {
      console.error("Error fetching user suggestions:", error);
      setUserSuggestions([]);
    }
  };

  const handleAddOrUpdateEmployee = async () => {
    const apiEndpoint = editingEmployee ? "update.php" : "create.php";
    const data = {
      id: editingEmployee ? editingEmployee.id : null,
      name,
      payRate,
      payMethod,
      company,
    };

    try {
      const response = await axios.post(`https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/payroll_manage/${apiEndpoint}`, data);
      toast.success(response.data.message);
      fetchEmployees(company);
      resetForm();
      setIsModalOpen(false);
    } catch (error) {
      console.error(`Error ${editingEmployee ? "updating" : "adding"} employee:`, error);
      toast.error(`Failed to ${editingEmployee ? "update" : "add"} employee.`);
    }
  };

  const handleDeleteEmployee = async (id) => {
    if (!window.confirm("Are you sure you want to delete this employee?")) return;
    try {
      const response = await axios.post("https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/payroll_manage/delete.php", { id, company });
      toast.success(response.data.message);
      fetchEmployees(company);
    } catch (error) {
      console.error("Error deleting employee:", error);
      toast.error("Failed to delete employee.");
    }
  };

  const handleEditEmployee = (employee) => {
    setName(employee.name);
    setPayRate(employee.pay_rate);
    setPayMethod(employee.pay_method);
    setEditingEmployee(employee);
    setIsModalOpen(true);
  };

  const handleNameInputChange = (e) => {
    const value = e.target.value;
    setName(value);
    if (value.length >= 2) {
      fetchUserSuggestions(value);
    }
  };

  const closeModal = () => {
    setIsChartModalOpen(false);
  };
  const resetForm = () => {
    setName("");
    setPayRate(0);
    setPayMethod("Direct deposit");
    setEditingEmployee(null);
    setUserSuggestions([]);
  };

  const handleChartClick = () => {
    if (employees.length > 2) {
      setIsChartModalOpen(true);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.setFontSize(20);
    doc.text("Employee List", 14, 22);

    const employeeData = employees.map(emp => [emp.name, emp.pay_rate, emp.pay_method]);
    doc.autoTable({
      head: [['Name', 'Pay Rate', 'Pay Method']],
      body: employeeData,
      startY: 30,
    });

    doc.save("employees_payroll.pdf");
  };

  return (
    <div className="p-5 max-w-[1200px] mx-auto bg-[#f4f4f9]">
      {payrollData && (
        <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-3 mb-8">
          <div className="bg-white rounded-lg shadow p-6 text-center">
            <p className="text-green-600 text-3xl font-bold">£{payrollData.totalPayrollCost}</p>
            <h3 className="text-gray-500">Total Payroll Cost</h3>
          </div>
          <div className="bg-white rounded-lg shadow p-6 text-center">
            <Pie onClick={handleChartClick} data={{ datasets: [{ data: payrollData.breakdown.map((b) => b.amount), backgroundColor: COLORS }] }} options={{ responsive: true }} onClick={handleChartClick} />
            <div className="tooltip-container">
              <div className="icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="50" height="50">
                  <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm0 22c-5.518 0-10-4.482-10-10s4.482-10 10-10 10 4.482 10 10-4.482 10-10 10zm-1-16h2v6h-2zm0 8h2v2h-2z"></path>
                </svg>
              </div>
              <div className="tooltip"><p>click on the chart to view the employee payroll</p></div>
            </div>
          </div>
          <div className="bg-white p-6 rounded-lg shadow">
            <p>New payroll due Friday, {payrollData.nextPayrollDate}</p>
            <a href="/" className="text-green-500">Paycheck list</a>
            <p className="mt-2">Want to automate your payroll taxes? <a href="/" className="text-green-500">Checkout full service payroll</a></p>
          </div>
        </div>
      )}

<div className="flex flex-col lg:flex-row mb-4 space-y-4 lg:space-y-0 space-x-0 lg:space-x-4">
  <div className="flex w-full lg:w-3/4 items-center">
    <input 
      type="text" 
      value={searchTerm} 
      onChange={handleSearch} 
      placeholder='Search "Luke Daniel Johnson"' 
      className="flex-1 p-3 border border-gray-300 rounded-l-lg focus:outline-none focus:ring-2 focus:ring-green-500"
    />
    <button 
      className="bg-green-500 text-white font-medium py-3 px-6 rounded-r-lg hover:bg-green-600 transition duration-300"
    >
      Search
    </button>
  </div>
  <div className="flex w-full lg:w-auto space-x-2">
    <button 
      onClick={() => { resetForm(); setIsModalOpen(true); }} 
      className="bg-blue-500 text-white font-medium py-2 px-4 rounded hover:bg-blue-600 transition duration-300 w-full lg:w-auto"
    >
      Create Payroll
    </button>
    <button 
      onClick={exportToPDF} 
      className="bg-green-500 flex items-center text-white font-medium py-2 px-4 rounded hover:bg-green-600 transition duration-300 w-full lg:w-auto"
    >
      <FaFilePdf className="mr-2" />Export to PDF
    </button>
  </div>
</div>
           {/* Styles for modal and layout */}
     <style jsx>{`
        .grid {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
          gap: 20px;
          margin-bottom: 20px;
        }
        .card {
          background: white;
          border-radius: 8px;
          padding: 20px;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
          text-align: center;
        }
        .employee-table {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
          gap: 15px;
        }
        .employee-card {
          background: white;
          border-radius: 8px;
          padding: 15px;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        }
        .actions {
          display: flex;
          justify-content: space-between;
          margin-top: 10px;
        }
        .icon {
          cursor: pointer;
          color: #007bff;
        }
        .icon:hover {
          color: #0056b3;
        }
        .modal-overlay {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.7);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
        }
        .modal-content {
          background-color: #fff;
          padding: 20px;
          border-radius: 8px;
          width: 90%;
          max-width: 600px;
        }
        // .button {
        //   display: block;
        //   margin-left: 20px;
        //   background-color: rgba(147, 147, 147, 1);
        //   margin-top: 3px;
        //   color: white;
        //   border: none;
        //   padding: 10px 15px;
        //   border-radius: 5px;
        //   cursor: pointer;
        //   transition: background-color 0.3s;
        // }
        // .button1 {
        //   display: block;
        //   margin-left: 20px;
        //   background-color: blue;
        //   margin-top: 3px;
         
        //   margin-bottom: 12px;
        //   color: white;
        //   border: none;
        //   padding: 10px 15px;
        //   border-radius: 5px;
        //   cursor: pointer;
        //   transition: background-color 0.3s;
        // }
        // .button:hover {
        //   background-color: #0056b3;
        // }

        .tooltip-container {
          position: relative;
          display: inline-block;
          margin: 20px;
        }

        /* Icon styling */
        .icon {
          margin-left: 230px;
          width: 20px;
          height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }

        /* SVG Animation: Rotate and scale effect */
        .icon svg {
          transition: transform 0.5s ease-in-out;
        }

        /* Tooltip styling */
        .tooltip {
          visibility: hidden;
          width: 200px;
          background-color: #333;
          color: #fff;
          text-align: center;
          border-radius: 5px;
          padding: 10px;
          position: absolute;
          bottom: 125%; /* Position above the icon */
          left: 50%;
          margin-left: 10px; /* Center the tooltip */
          opacity: 0;
          transition: opacity 0.5s, transform 0.5s;
          transform: translateY(10px);
        }

        /* Tooltip Arrow */
        .tooltip::after {
          content: "";
          position: absolute;
          top: 100%;
          left: 50%;
          margin-left: -5px;
          border-width: 5px;
          border-style: solid;
          border-color: #333 transparent transparent transparent;
        }

        /* Show tooltip on hover */
        .tooltip-container:hover .tooltip {
          visibility: visible;
          opacity: 1;
          transform: translateY(0);
        }

        .tooltip-container:hover .tooltip {
          visibility: visible;
          opacity: 1;
          transform: translateY(0);
        }
     ` }</style>

      <div className="bg-white rounded-lg shadow overflow-x-auto mb-4">
        <table className="min-w-full table-auto">
          <thead>
            <tr className="bg-gray-200">
              <th className="px-4 py-2">Name</th>
              <th className="px-4 py-2">Pay Rate</th>
              <th className="px-4 py-2">Pay Method</th>
              <th className="px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {employees.filter(emp => emp.name.toLowerCase().includes(searchTerm.toLowerCase())).map((employee) => (
              <tr key={employee.id}>
                <td className="border px-4 py-2">{employee.name}</td>
                <td className="border px-4 py-2">£{employee.pay_rate}</td>
                <td className="border px-4 py-2">{employee.pay_method}</td>
                <td className="border px-4 py-2">
                  <button onClick={() => handleEditEmployee(employee)} className="text-blue-500 hover:underline">Edit</button>
                  <button onClick={() => handleDeleteEmployee(employee.id)} className="ml-4 text-red-500 hover:underline">Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => setIsModalOpen(false)}>&times;</span>
            <h2>{editingEmployee ? "Edit Employee" : "Add Employee"}</h2>
            <input type="text" value={name} onChange={handleNameInputChange} placeholder="Name" className="p-2 mb-4 border border-gray-300 rounded" />
            <input type="number" value={payRate} onChange={(e) => setPayRate(e.target.value)} placeholder="Pay Rate" className="p-2 mb-4 border border-gray-300 rounded" />
            <select value={payMethod} onChange={(e) => setPayMethod(e.target.value)} className="p-2 mb-4 border border-gray-300 rounded">
              <option value="Direct deposit">Direct deposit</option>
              <option value="Cheque">Cheque</option>
            </select>
            <button onClick={handleAddOrUpdateEmployee} className="bg-blue-500 text-white py-2 px-4 rounded">{editingEmployee ? "Update Employee" : "Add Employee"}</button>
          </div>
        </div>
      )}

{isChartModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button onClick={closeModal} className="close-button">Close</button>
            <Pie 
              data={{ datasets: [{ data: payrollData.breakdown.map((b) => b.amount), backgroundColor: COLORS }] }}
              options={{ responsive: true }}
            />
          </div>
        </div>
      )}
 
    </div>
  );
};

// const ChartModal = ({ data, onClose }) => {
//   const chartData = {
//     labels: data.map(item => item.label),
//     datasets: [{
//       data: data.map(item => item.amount),
//       backgroundColor: ['#ff6384', '#36a2eb', '#cc65fe', '#ffce56', '#ff5733'],
//     }],
//   };




export default Em;
