import React, { useEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Login from './components/Login';
import Register from './components/Register';
import Home from './components/Home';
import EmployeeProfile from './components/EmployeeProfile';
import Loading from './components/ui/loading/Loading';
import Time from './components/Time';
import TaskList from './components/TaskList';
import Employement from './components/calender/contracts/Employement';
import Overtimee from './components/overtime/Overtimee';
import Personal from './components/emp/Personal';
import Emergency from './components/emer/Emergency';
import Organ from './components/organisation/Organ';
import TaskCalendar from './components/TaskCalander';
import HrProfile from './components/HrProfile';
import { ToastContainer } from 'react-toastify';
import Docu from './components/Docu';
import Timelog from './components/organisation/Timelog';
import Cal from './components/calender/Cal';
import Documentt from './components/calender/Documentt';
import Cou from './components/calender/Cou';
import EmployeeTable from './components/calender/EmployeeTable';
import DataDash from './components/data/DataDash';
import Pa from './components/pay/Pa';
import Report from './components/pay/Report';
import ForgotPassword from './components/ForgotPassword';
import CompanyList from './components/CompanyList';
import Company from './components/Company';
import Home1 from './Home1';

function App() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simulate a loading delay and fetch user info from localStorage
        setTimeout(() => {
            
            setLoading(false);
        }, 2000);
    }, []);

    return (
        <Router>
            <ToastContainer />
            {loading ? (
                <Loading /> // Show loading screen if data is still loading
            ) : (
                <Routes>
                    {/* Common Routes for all roles */}
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/dashboard" element={<Home />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/" element={<Home1 />} />

                    {/* Employee Routes */}
                    <Route path="/employee/:id" element={<Employement />} />
                    <Route path="/time-management/:id" element={<Time />} />
                    <Route path="/emp/:id" element={<EmployeeProfile />} />
                    <Route path="/task" element={<TaskList />} />
                    <Route path="/time-overtime/:id" element={<Overtimee />} />
                    <Route path="/personal/:id" element={<Personal />} />
                    <Route path="/emer/:id" element={<Emergency />} />
                    <Route path="/org" element={<Organ />} />
                    <Route path="/timesheet/:id" element={<Timelog />} />
                    <Route path="/documents/:id" element={<Docu />} />
                    <Route path="/oorg" element={<TaskCalendar />} />
                    <Route path="/calender" element={<Cal />} />
                    <Route path="/hr" element={<HrProfile />} />
                    <Route path="/documents" element={<Documentt />} />
                    <Route path="/e-learning" element={<Cou />} />
                    <Route path="/e" element={<EmployeeTable />} />
                    <Route path="/data" element={<DataDash />} />
                    <Route path="/payroll" element={<Pa />} />
                    <Route path="/expenses" element={<Report />} />

                    {/* Admin Routes */}
                    
                        <>
                            <Route path="/admin" element={<CompanyList />} />
                            <Route path="/admin/company" element={<Company />} />
                        </>
                   
                </Routes>
            )}
        </Router>
    );
}

export default App;
