import React from 'react'
import Sidebar from '../Sidebar'
import Navbar from '../Navbar'
import Data from './Data'


const DataDash = () => {
  return (
	<section>
    	 <section className="flex">
      <Sidebar />
      <div className="flex-1">
        <Navbar />
        <Data/>
      </div>
    </section>
  </section>
  )
}

export default DataDash