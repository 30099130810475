import React from "react";
import profile from "../assets/profile.svg";
import finger from "../assets/finger.svg";
import employe from "../assets/employe.svg";
import face from "../assets/face.svg";

const Work = () => {
  return (
    <section className="mt-14 font-poppins">
      <div data-aos="fade-up"  data-aos-duration="1000" className="text-center">
        <h1 className="text-4xl font-bold mt-4">Here’s how it works</h1>
        <div className="border-b-4 border-yellow-500 w-48 mx-auto mb-6"></div>
        <button className="h-10 p-2 w-64 text-yellow-500 rounded-xl shadow-lg mt-12 bg-yellow-400 border mx-auto">Genix is Fast and Easy</button>
      </div>
      <div data-aos="fade-up"  data-aos-duration="1000" className="flex flex-wrap justify-center mt-10 space-x-0 space-y-16 md:space-y-0 md:space-x-9">
        <div className="h-52 rounded-t-2xl pt-5 w-52 border mx-2">
          <img src={profile} alt="/" className="pl-7" />
          <div className="bg-yellow-400 h-10 mt-6 pt-2 rounded-b-2xl">
            <h1 className="text-center">Register our portal</h1>
          </div>
        </div>
        <div data-aos="fade-up"  data-aos-duration="1000" className="h-52 rounded-t-2xl pt-5 w-52 border mx-2">
          <img src={finger} alt="/" className="pl-7" />
          <div className="bg-yellow-400 h-10 mt-6 pt-2 rounded-b-2xl">
            <h1 className="text-center">Subscribe to your plan</h1>
          </div>
        </div>
        <div data-aos="fade-up"  data-aos-duration="1000" className="h-52 rounded-t-2xl pt-5 w-52 border mx-2">
          <img src={employe} alt="/" className="pl-7" />
          <div className="bg-yellow-400 h-10 mt-6 pt-2 rounded-b-2xl">
            <h1 className="text-center">Onboard Employees</h1>
          </div>
        </div>
        <div data-aos="fade-up"  data-aos-duration="1000" className="h-52 rounded-t-2xl pt-5 w-52 border mx-2">
          <img src={face} alt="/" className="pl-7" />
          <div className="bg-yellow-400 h-10 mt-6 pt-2 rounded-b-2xl">
            <h1 className="text-center">Stay Relaxed</h1>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Work;
