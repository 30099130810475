import React ,{useEffect} from 'react'
import Navbar from './components/component/Navbar'
import Features from './components/component/Features'
import Manage from './components/component/Manage'
import Faq from './components/component/Faq'
import Options from './components/component/Options'
import BudgetFeatures from './components/component/BudgetFeatures'
import Offer from './components/component/Offer'
import Work from './components/component/Work'
import Pricing from './components/component/Pricing'
import Question from './components/component/Question'
import Footer from './components/component/Footer'

import AOS from 'aos';
import 'aos/dist/aos.css';

const Home1 = () => {
	useEffect(() => {
		AOS.init(); // Initialize AOS
	  }, []);
  return (
	<section className='overflow-x-hidden overflow-y-sroll'>
	<Navbar/>
	<Features/>
	<Manage/>
	<Faq/>
	<Options/>
	<BudgetFeatures/>
	<Offer/>
	<Work/>
	<Pricing/>
	<Question/>
	<Footer/>
	
	</section>
  )
}

export default Home1;