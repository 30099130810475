import React from "react";
import people from "../assets/people.svg";
import track from "../assets/track.svg";
import alert1 from "../assets/alert1.svg";

const Faq = () => {
  return (
    <section className="mt-12 font-poppins">
      <div className="flex flex-col items-center">
        {/* Title */}
        <div className="flex flex-col items-center justify-center h-32">
          <p className="tracking-wide text-center text-yellow-500">
            <span className="text-4xl md:text-5xl">What is </span>
            <span className="text-5xl md:text-6xl font-bold">Genix?</span>
          </p>
          {/* Controlled bottom border */}
          <div className="border-b-4 border-yellow-500 w-36 mt-2"></div>
        </div>

        {/* Intro Text */}
        <ul
          data-aos="zoom-in"
          data-aos-duration="1000"
          className="mx-8 md:mx-36 mt-8 space-y-5 tracking-wide text-lg text-yellow-500 text-center"
        >
          <li>Genix is an effective platform to manage your sponsored employees with legal compliance.</li>
          <li>Genix helps you to manage the employee lifecycle from onboard to offboard the job.</li>
          <li>Employees can manage their profile, personal information, timesheets, and tasks.</li>
          <li>Employers can get all employee and project-related reports from a single user interface.</li>
          <li>Genix helps businesses save time & money by simplifying HR overheads.</li>
        </ul>

        {/* Section 1: Manage */}
        <div
          data-aos="fade-up"
          data-aos-duration="1000"
          className="flex flex-col md:flex-row items-center justify-center mx-4 md:mx-auto mt-8 border border-yellow-500 rounded-3xl w-full md:w-[900px] p-6"
        >
          <div className="space-y-5 tracking-wide text-left w-full md:w-2/4">
            <h1 className="text-xl font-semibold">1. Manage</h1>
            <p>
              It's a nonstop solution to onboard, manage, and maintain employee records.
            </p>
          </div>
          <div className="flex items-center justify-center md:justify-start mt-4 md:mt-0 md:w-1/2">
            <img src={people} alt="People" className="w-32 h-auto" />
          </div>
        </div>

        {/* Section 2: Track */}
        <div
          data-aos="fade-up"
          data-aos-duration="1000"
          className="flex flex-col md:flex-row items-center justify-center mx-4 md:mx-auto mt-8 border border-yellow-500 rounded-3xl w-full md:w-[900px] p-6"
        >
          <div className="flex items-center justify-center md:justify-start mt-4 md:mt-0 md:w-1/2">
            <img src={track} alt="Track" className="w-32 h-auto" />
          </div>
          <div className="space-y-5 tracking-wide text-left w-full md:w-2/4">
            <h1 className="text-xl font-semibold">2. Track</h1>
            <p>
              Track employees with their workload, timesheets, tasks, and absences.
            </p>
          </div>
        </div>

        {/* Section 3: Alert */}
        <div
          data-aos="fade-up"
          data-aos-duration="1000"
          className="flex flex-col md:flex-row items-center justify-center mx-4 md:mx-auto mt-8 border border-yellow-500 rounded-3xl w-full md:w-[900px] p-6"
        >
          <div className="space-y-5 tracking-wide text-left w-full md:w-2/4">
            <h1 className="text-xl font-semibold">3. Alert</h1>
            <p>
              Get alerts on key dates like passport, visas, RTW expiry well in advance.
            </p>
          </div>
          <div className="flex items-center justify-center md:justify-start mt-4 md:mt-0 md:w-1/2">
            <img src={alert1} alt="Alert" className="w-32 h-auto" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
