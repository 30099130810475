import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MdDelete, MdDownload } from "react-icons/md";

const CourseManager = () => {
    const [courses, setCourses] = useState([]);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [file, setFile] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [editingCourseId, setEditingCourseId] = useState(null);

    // Retrieve company name and user info from local storage
    const user = JSON.parse(localStorage.getItem('user'));
    const companyName = user?.company || '';

    useEffect(() => {
        fetchCourses();
    }, []);

    const fetchCourses = async () => {
        try {
            const response = await axios.get(`https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/courses/read.php?company=${companyName}`);
            if (Array.isArray(response.data)) {
                setCourses(response.data);
            } else {
                console.warn('Unexpected data format:', response.data);
                setCourses([]);
            }
        } catch (error) {
            console.error('Error fetching courses:', error);
            toast.error('Please re-enter title and description.');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
        formData.append('company', companyName);
        if (file) formData.append('course_file', file);

        try {
            const response = isEditing
                ? await axios.put(`https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/courses/update.php`, formData, {
                    params: { id: editingCourseId }
                })
                : await axios.post(`https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/courses/create.php`, formData);

            if (response.data.status === 'success') {
                toast.success(`Course ${isEditing ? 'updated' : 'created'} successfully.`);
                fetchCourses();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error(`Error ${isEditing ? 'updating' : 'creating'} course:`, error);
            toast.error(`Failed to ${isEditing ? 'update' : 'create'} course.`);
        }
        
        resetForm();
    };

    const handleDelete = async (id) => {
        if (window.confirm('Are you sure you want to delete this course?')) {
            try {
                const response = await axios.delete(`https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/courses/delete.php`, {
                    params: { id, company: companyName }
                });
                if (response.data.status === 'success') {
                    toast.success('Course deleted successfully.');
                    fetchCourses();
                } else {
                    toast.error(response.data.message);
                }
            } catch (error) {
                console.error('Error deleting course:', error);
                toast.error('Failed to delete course.');
            }
        }
    };

    const resetForm = () => {
        setTitle('');
        setDescription('');
        setFile(null);
        setIsEditing(false);
        setEditingCourseId(null);
    };

    return (
        <div className="max-w-3xl mx-20  p-8 bg-white shadow-lg rounded-lg mt-10">
            <h1 className="text-3xl font-bold text-center text-gray-800 mb-6">E-Learning Management System</h1>

            {user.role === "hr" && (
                <form onSubmit={handleSubmit} className="space-y-6">
                    <div>
                        <label className="block text-lg font-medium text-gray-700">Course Title:</label>
                        <input 
                            type="text" 
                            value={title} 
                            onChange={(e) => setTitle(e.target.value)} 
                            required 
                            className="mt-2 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-blue-300" 
                        />
                    </div>
                    <div>
                        <label className="block text-lg font-medium text-gray-700">Description:</label>
                        <textarea 
                            value={description} 
                            onChange={(e) => setDescription(e.target.value)} 
                            required 
                            className="mt-2 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-blue-300" 
                        />
                    </div>
                    <div>
                        <label className="block text-lg font-medium text-gray-700">Upload File:</label>
                        <input 
                            type="file" 
                            onChange={(e) => setFile(e.target.files[0])} 
                            className="mt-2 block w-full text-sm text-gray-500 border border-gray-300 rounded-md" 
                        />
                    </div>
                    <div className="flex justify-between space-x-4">
                        <button type="submit" className="w-full py-3 px-4 bg-blue-600 text-white font-semibold rounded-md shadow hover:bg-blue-500 transition duration-200">
                            {isEditing ? 'Update Course' : 'Add Course'}
                        </button>
                        <button type="button" onClick={resetForm} className="w-full py-3 px-4 bg-gray-300 text-gray-700 font-semibold rounded-md shadow hover:bg-gray-200 transition duration-200">
                            Cancel
                        </button>
                    </div>
                </form>
            )}

            <h2 className="text-2xl font-semibold text-gray-800 mt-10">Course List</h2>
            <table className="w-full mt-4 bg-gray-100 rounded-lg overflow-hidden shadow-lg">
                <thead>
                    <tr className="bg-gray-300 text-gray-600 text-left text-lg">
                        <th className="py-4 px-6">Title</th>
                        <th className="py-4 px-6">Description</th>
                        <th className="py-4 px-6">File</th>
                        <th className="py-4 px-6">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {courses.map(course => (
                        <tr key={course.id} className="hover:bg-gray-50">
                            <td className="py-4 px-6 border-t">{course.title}</td>
                            <td className="py-4 px-6 border-t">{course.description}</td>
                            <td className="py-4 px-6 border-t">
                                {course.file_path && (
                                    <a href={course.file_path} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline flex items-center">
                                        <MdDownload className="mr-1" /> Download
                                    </a>
                                )}
                            </td>
                            {user.role === 'hr' && (
                                <td className="py-4 px-6 border-t">
                                    <button onClick={() => handleDelete(course.id)} className="text-red-600 hover:underline text-xl">
                                        <MdDelete />
                                    </button>
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default CourseManager;
