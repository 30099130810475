import React from 'react'
import Sidebar from '../Sidebar'
import Navbar from '../Navbar'
import Org from './Org'

const Organ = () => {
  return (
	<>
	
	<section className="flex">
      <Sidebar />
      <div className="flex-1">
        <Navbar />
        <Org/>
      </div>
    </section>
	</>
  )
}

export default Organ