import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import Modal from 'react-modal'; // Ensure you've installed react-modal
import 'react-calendar/dist/Calendar.css';

const TaskCalendar = () => {
    const [taskDetails, setTaskDetails] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [tasksForDate, setTasksForDate] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        const userId = user ? user.id : null;

        const fetchTasks = async () => {
            if (!userId) return; // Exit if userId is not available

            const response = await fetch('https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/task/fetch_tasks.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_id: userId }),
            });

            const data = await response.json();

            if (data.status === 'success') {
                setTaskDetails(data.tasks);
            } else {
                setMessage(data.message);
            }
        };

        fetchTasks();
    }, []);

    const handleDateClick = (date) => {
        const selectedTasks = taskDetails.filter(task => {
            const startDate = new Date(task.start_time).toDateString();
            const stopDate = task.stop_time ? new Date(task.stop_time).toDateString() : new Date().toDateString();
            const clickedDate = date.toDateString();
            return clickedDate >= startDate && clickedDate <= stopDate;
        });

        setTasksForDate(selectedTasks);
        setSelectedDate(date);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setTasksForDate([]);
    };

    return (
        <div>
            <Calendar
                onChange={setSelectedDate}
                value={selectedDate}
                onClickDay={handleDateClick}
            />

            <Modal isOpen={isModalOpen} onRequestClose={closeModal}>
                <h2>Tasks on {selectedDate.toDateString()}</h2>
                {tasksForDate.length > 0 ? (
                    <ul>
                        {tasksForDate.map(task => (
                            <li key={task.id}>
                                <p><strong>Task Name:</strong> {task.task_name}</p>
                                <p><strong>Start Time:</strong> {new Date(task.start_time).toLocaleTimeString()}</p>
                                <p><strong>Stop Time:</strong> {task.stop_time ? new Date(task.stop_time).toLocaleTimeString() : 'Still Running'}</p>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>No tasks for this date.</p>
                )}
                <button onClick={closeModal}>Close</button>
            </Modal>

            {message && <p>{message}</p>}
        </div>
    );
};

export default TaskCalendar;
