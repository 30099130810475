import React from 'react';
import set from '../assets/set.svg';
import div2 from '../assets/div2.svg';
import div3 from '../assets/div3.svg';
import div4 from '../assets/div4.svg';
import div5 from '../assets/div5.svg';
import div6 from '../assets/div6.svg';

const Question = () => {
  return (
    <section className='px-6' id="faq">
      <h1 className="text-4xl text-center font-bold mt-4">Frequently Asked Questions</h1>
      <div className="mx-auto mt-2 border-b-4 border-yellow-500 w-48 mb-8"></div>
      <button className="h-12 p-2 w-80 mx-auto block text-yellow-500 rounded-xl shadow-lg hover:bg-yellow-500 hover:text-white transition-colors duration-300 bg-yellow-400 border mb-8">
        Get Started with Our Useful Answers
      </button>

      <div className="space-y-6">
        {/* FAQ Item 1 */}
        <div data-aos="fade-up"  data-aos-duration="1000" className="flex flex-col md:flex-row items-center p-6 bg-white border border-gray-300 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300">
          <div className="w-16 mb-4 md:mb-0 md:mr-4">
            <img src={set} alt="FAQ Icon" className="w-full h-auto" />
          </div>
          <div className="flex-1">
            <h1 className="text-xl font-semibold mb-2">How does Genix work?</h1>
            <p className="text-gray-600">
              Genix was developed with detailed study on the immigration system which helps your business to maintain your employees in compliance with the Genix guidelines.
            </p>
          </div>
        </div>

        {/* FAQ Item 2 */}
        <div data-aos="fade-up"  data-aos-duration="1000" className="flex flex-col md:flex-row items-center p-6 bg-white border border-gray-300 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300">
          <div className="flex-1 md:mr-4">
            <h1 className="text-xl font-semibold mb-2">How Genix can help my business?</h1>
            <p className="text-gray-600">
            The system guides you with a step by step process to onboard a employee with all compliance checks, It will also help to stay compliant with changing rules.
            </p>
          </div>
          <div className="w-16 mb-4 md:mb-0">
            <img src={div2} alt="FAQ Icon" className="w-full h-auto" />
          </div>
        </div>

        {/* FAQ Item 3 */}
        <div data-aos="fade-up"  data-aos-duration="1000" className="flex flex-col md:flex-row items-center p-6 bg-white border border-gray-300 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300">
          <div className="w-16 mb-4 md:mb-0 md:mr-4">
            <img src={div3} alt="FAQ Icon" className="w-full h-auto" />
          </div>
          <div className="flex-1">
            <h1 className="text-xl font-semibold mb-2">Why your business needs Genix?</h1>
            <p className="text-gray-600">
            It is your legal responsibility to adhere to the immigration rules & guidelines, We help you to achieve and maintain compliance with our system.
            </p>
          </div>
        </div>

        {/* FAQ Item 4 */}
        <div data-aos="fade-up"  data-aos-duration="1000" className="flex flex-col md:flex-row items-center p-6 bg-white border border-gray-300 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300">
          <div className="flex-1 md:mr-4">
            <h1 className="text-xl font-semibold mb-2">How secure is your information?</h1>
            <p className="text-gray-600">
            We follow industry standard security principles in handing your data, All your records are stored within UK based datacenters with state of the art infrastructure.
            </p>
          </div>
          <div className="w-16 mb-4 md:mb-0">
            <img src={div4} alt="FAQ Icon" className="w-full h-auto" />
          </div>
        </div>

        {/* FAQ Item 5 */}
        <div data-aos="fade-up"  data-aos-duration="1000" className="flex flex-col md:flex-row items-center p-6 bg-white border border-gray-300 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300">
          <div className="w-16 mb-4 md:mb-0 md:mr-4">
            <img src={div5} alt="FAQ Icon" className="w-full h-auto" />
          </div>
          <div className="flex-1">
            <h1 className="text-xl font-semibold mb-2">What happens if you are not satisfied?</h1>
            <p className="text-gray-600">
            we value our Customers and always wanted our Customers stay happy and Satisfied, We offer flexible refund policy if you don't Satisfied with our services.
            </p>
          </div>
        </div>

        {/* FAQ Item 6 */}
        <div data-aos="fade-up"  data-aos-duration="1000" className="flex flex-col md:flex-row items-center p-6 bg-white border border-gray-300 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300">
          <div className="flex-1 md:mr-4">
            <h1 className="text-xl font-semibold mb-2">Where can I use Genix?</h1>
            <p className="text-gray-600">
            You can access our portal anytime from any part of the world, all you need is a internet and a compatible device.
            </p>
          </div>
          <div className="w-16 mb-4 md:mb-0">
            <img src={div6} alt="FAQ Icon" className="w-full h-auto" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Question;
