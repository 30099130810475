import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';

const TaskList = () => {
    const [taskDetails, setTaskDetails] = useState([]);
    const user = JSON.parse(localStorage.getItem('user'));
    const userId = user.id;
    const [message, setMessage] = useState('');
    const [currentTaskId, setCurrentTaskId] = useState(null);
    const [elapsedTime, setElapsedTime] = useState(0);
    const [isRunning, setIsRunning] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);

    const fetchTasks = async (date) => {
        try {
            const response = await fetch('https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/task/fetch_tasks.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_id: userId, date }),
            });

            const data = await response.json();

            if (data.status === 'success') {
                setTaskDetails(data.tasks);
            } else {
                setMessage(data.message);
            }
        } catch (error) {
            setMessage('Error fetching tasks. Please try again.');
            console.error(error);
        }
    };

    useEffect(() => {
        fetchTasks(selectedDate);
    }, [userId, selectedDate]);

    useEffect(() => {
        let interval = null;
        if (isRunning) {
            interval = setInterval(() => {
                setElapsedTime((prevTime) => prevTime + 1);
            }, 1000);
        } else if (!isRunning && elapsedTime !== 0) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [isRunning]);

    const handleStartTask = async (taskId) => {
        if (isRunning) {
            setMessage('A task is already running. Stop it before starting a new one.');
            return;
        }

        const task = taskDetails.find((task) => task.id === taskId);
        if (!task) {
            setMessage('Task not found.');
            return;
        }

        const response = await fetch('https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/task/start_timer.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ time_log_id: taskId }),
        });

        const data = await response.json();

        if (data.status === 'success') {
            setCurrentTaskId(taskId);
            setIsRunning(true);
            setElapsedTime(0);
            setMessage('Task started successfully.');
        } else {
            setMessage(data.message);
        }
    };

    const handleStopTask = async () => {
        if (!currentTaskId) {
            setMessage('No task is currently running.');
            return;
        }

        const response = await fetch('https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/task/stop_task.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ time_log_id: currentTaskId }),
        });

        const data = await response.json();

        if (data.status === 'success') {
            setTaskDetails((prevTasks) =>
                prevTasks.map((task) => {
                    if (task.id === currentTaskId) {
                        return {
                            ...task,
                            stop_time: new Date().toISOString(),
                        };
                    }
                    return task;
                })
            );
            setIsRunning(false);
            setCurrentTaskId(null);
            setElapsedTime(0);
            setMessage('Task stopped successfully.');
            fetchTasks(selectedDate);
        } else {
            setMessage(data.message);
        }
    };

    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
        fetchTasks(event.target.value);
    };

    return (
        <div className="flex min-h-screen">
            {/* Sidebar Section */}
            <div className="w-1/5">
                <Sidebar />
            </div>
            
            
            {/* Main Content Section */}
            <div className="flex-1 p-6 bg-gray-100">
                <div className="container mx-auto p-6 bg-white rounded-lg shadow-md">
                    <h1 className="text-3xl font-semibold text-center mb-6">User Task Manager</h1>
                    <div className="flex flex-col items-center mb-4">
                        <input
                            type="date"
                            value={selectedDate}
                            onChange={handleDateChange}
                            className="border rounded p-2 mb-4"
                        />
                        <button
                            onClick={handleStopTask}
                            className="bg-red-600 text-white font-semibold p-3 rounded-md transition duration-300 hover:bg-red-500 mb-4"
                        >
                            Stop Task
                        </button>
                        {message && <p className="mt-4 text-red-500">{message}</p>}
                    </div>
                    <div>
                        <h2 className="text-xl font-semibold">Your Tasks</h2>
                        <ul className="list-disc pl-5">
                            {taskDetails.map((task) => (
                                <li key={task.id} className="mt-2 flex justify-between items-center">
                                    <div>
                                        <strong>{task.task_name}</strong> - Started at: {task.start_time}
                                        {task.stop_time ? ` - Stopped at: ${task.stop_time}` : " - Running..."}
                                    </div>
                                    <div className="ml-4">
                                        {(task.id !== currentTaskId && !task.stop_time) && (
                                            <button
                                                onClick={() => handleStartTask(task.id)}
                                                className="bg-green-600 text-white font-semibold p-2 rounded-md mr-2 transition duration-300 hover:bg-green-500"
                                            >
                                                Start
                                            </button>
                                        )}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    {isRunning && (
                        <div className="mt-4">
                            <h2 className="text-xl font-semibold">Current Timer</h2>
                            <div className="flex items-center justify-center mt-2">
                                <span className="text-4xl">
                                    {`${String(Math.floor(elapsedTime / 3600)).padStart(2, '0')}:${String(Math.floor((elapsedTime % 3600) / 60)).padStart(2, '0')}:${String(elapsedTime % 60).padStart(2, '0')}`}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TaskList;
