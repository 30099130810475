import React, { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { link } from '../../constants/sidebar';
import { IoMdMail } from 'react-icons/io';
import axios from 'axios';
import Sidebar from '../Sidebar';

const Overtime = () => {
  const { id } = useParams(); // Get userId from URL
  const links = link(id);
  
  const [data, setData] = useState([]);
  const [totalOvertime, setTotalOvertime] = useState(0);
  const [employee, setEmployee] = useState(null);
  const [editing, setEditing] = useState({ start: 0, end: 0 });
  const [modalVisible, setModalVisible] = useState(false);
  
  // Fetch employee data and tasks data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const employeeResponse = await axios.get(`https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/read_users.php?id=${id}`);
        setEmployee(employeeResponse.data);

        const tasksResponse = await axios.get(`https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/task/overtime.php?user_id=${id}`);
        setData(tasksResponse.data.tasks);
        setTotalOvertime(tasksResponse.data.total_overtime);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    
    fetchData();
  }, [id]);

  const calculateOvertime = () => {
    const total = data.reduce((sum, task) => {
      if (!task.is_running) {
        return sum + (task.duration > 8 ? task.duration - 8 : 0);
      }
      return sum;
    }, 0);
    setTotalOvertime(total);
  };

  const handleTimelineClick = () => {
    setModalVisible(true);
  };

  const handleSave = () => {
    // Save the updated timeline (if needed)
    setModalVisible(false);
  };

  return (
    <section className='flex'>
     <div className="w-1/5 ">
        <Sidebar />
      </div>
      <div className="">
      {employee ? (
        <div className="flex flex-col md:flex-row ml-6 mt-10 bg-blue-100 h-auto p-5 rounded-3xl items-center justify-center">
          <div className="text-7xl border h-36 w-36 p-1 rounded-full bg-white flex items-center justify-center">
            {employee.profile_pic ? (
              <img src={employee.profile_pic} alt="" className="rounded-full h-36 w-36" />
            ) : (
              <span className="rounded-full text-gray-600 font-bold text-4xl">
                {employee.first_name.charAt(0)}{employee.last_name.charAt(0)}
              </span>
            )}
          </div>
          <div className="ml-0 md:ml-8 mt-4 flex md:mt-0 text-center md:text-left">
            <div>
              <h1 className="text-2xl font-bold">{employee.first_name} {employee.last_name}</h1>
              <p className="text-gray-600 mb-1 flex justify-center md:justify-start gap-3">
                <strong>Department:</strong> {employee.department}
              </p>
              <p className="text-gray-600 flex justify-center md:justify-start gap-3">
                <strong>Role:</strong> {employee.role}
              </p>
            </div>
            <div className="flex justify-end items-center pl-72">
              <p className="text-gray-600 mb-1 flex justify-center md:justify-start gap-3">
                <strong><IoMdMail color="green" /></strong> {employee.email}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div>Employee not found</div>
      )}

      <ul className='flex gap-16 ml-6 text-lg p-4 bg-blue-300 mt-8 rounded-t-2xl h-12'>
        {links.map((linkItem, index) => (
          <li key={index}>
            <NavLink
              to={linkItem.url}
              className={({ isActive }) =>
                isActive ? "text-blue-200 border-t-2 font-bold" : "text-gray-700"
              }
              end
            >
              {linkItem.name}
            </NavLink>
          </li>
        ))}
      </ul>

      <div className="font-poppins p-4 ml-3">
        <div className="overflow-x-auto rounded-lg shadow-md bg-white">
          <table className="min-w-full bg-white border-separate border-spacing-0">
            <thead>
              <tr>
                <th className="py-4 px-6 bg-gray-100 text-gray-800 font-semibold text-left">Task Name</th>
                <th className="py-4 px-6 bg-gray-100 text-gray-800 font-semibold text-left">Start Time</th>
                <th className="py-4 px-6 bg-gray-100 text-gray-800 font-semibold text-left">Stop Time</th>
                <th className="py-4 px-6 bg-gray-100 text-gray-800 font-semibold text-left">Duration (hrs)</th>
                <th className="py-4 px-6 bg-gray-100 text-gray-800 font-semibold text-left">Is Running</th>
              </tr>
            </thead>
            <tbody>
              {data.map((task, index) => (
                <tr key={index} className="border-b">
                  <td className="py-4 px-6 text-gray-700">{task.task_name}</td>
                  <td className="py-4 px-6 text-gray-700">{task.start_time}</td>
                  <td className="py-4 px-6 text-gray-700">{task.stop_time}</td>
                  <td className="py-4 px-6 text-gray-700">{task.duration}</td>
                  <td className="py-4 px-6 text-gray-700">{task.is_running ? 'Yes' : 'No'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Calculate Overtime Button */}
        <div className="mt-6 text-center">
          <button
            onClick={calculateOvertime}
            className="px-6 py-2 bg-blue-600 text-white rounded-md shadow-md hover:bg-blue-700"
          >
            Calculate Overtime
          </button>
          {totalOvertime > 0 && (
            <p className="mt-4 text-green-600 font-semibold">
              Total Overtime: {totalOvertime} hours
            </p>
          )}
        </div>

        {/* Modal for time editing */}
        {modalVisible && (
          <div className="fixed inset-0 bg-gray-800 bg-opacity-60 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full">
              <h3 className="text-xl mb-4 font-semibold">Edit Time Shift</h3>
              <div className="flex flex-col gap-6">
                <div>
                  <label className="block text-gray-700 font-medium mb-2">Start Time:</label>
                  <input
                    type="time"
                    value={editing.start}
                    onChange={(e) => setEditing({ ...editing, start: e.target.value })}
                    className="w-full px-3 py-2 border rounded-lg"
                  />
                </div>
                <div>
                  <label className="block text-gray-700 font-medium mb-2">End Time:</label>
                  <input
                    type="time"
                    value={editing.end}
                    onChange={(e) => setEditing({ ...editing, end: e.target.value })}
                    className="w-full px-3 py-2 border rounded-lg"
                  />
                </div>
              </div>

              <div className="mt-6 flex justify-end gap-4">
                <button onClick={handleSave} className="px-4 py-2 bg-blue-600 text-white rounded-md">
                  Save
                </button>
                <button onClick={() => setModalVisible(false)} className="px-4 py-2 bg-gray-400 text-white rounded-md">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      </div>
    </section>
  );
};

export default Overtime;
