import React from "react";
import document from "../assets/document.svg";
import alert from "../assets/alert.svg";
import puzzle from "../assets/puzzle.svg";

const Manage = () => {
  return (
    <section id="about" className="mt-20 tracking-wide">
      <div className="container font-poppins mx-auto px-4 lg:px-0">
        {/* Title Section */}
        <h1 data-aos="fade-up" data-aos-duration="1000" className="font-semibold tracking-wide text-center lg:text-left">
          <h1 className="text-2xl text-center md:text-3xl lg:text-4xl">
            You deserve easy access to
          </h1>
          <br />
        </h1>
        <p data-aos="fade-up" data-aos-duration="1000" className="text-3xl flex justify-center md:text-4xl lg:text-5xl font-bold mt-8 underline underline-offset-8 text-center lg:text-left ">
          manage workforce
        </p>
        <button data-aos="fade-up" data-aos-duration="1000" className="mt-9 md:w-80 lg:w-96 text-gray-600 rounded-xl h-12 md:h-14 lg:h-16 bg-yellow-400 mx-auto block">
          Genix is for you if you want to
        </button>

        {/* Features Section */}
        <div className="font-poppins flex flex-col md:flex-row justify-center items-center mt-12 space-y-8 md:space-y-0 md:space-x-8">
          {/* Manage Documents Card */}
          <div className="bg-yellow-100 h-80 md:h-96 w-64 md:w-72 rounded-2xl shadow-xl flex flex-col items-center justify-center transition-transform transform hover:scale-105">
            <img data-aos="fade-up" data-aos-duration="1000" className="w-28 h-28" src={document} alt="Manage Documents" />
            <h1 data-aos="fade-up" data-aos-duration="1000" className="text-lg font-semibold pt-6 text-center">
              Manage Documents
            </h1>
            <p data-aos="fade-up" data-aos-duration="1000" className="text-sm pt-3 text-center">
              Maintain all legal <br /> documents.
            </p>
          </div>

          {/* Alert Card */}
          <div className="bg-yellow-100 h-80 md:h-96 w-64 md:w-72 rounded-2xl shadow-xl flex flex-col items-center justify-center transition-transform transform hover:scale-105">
            <img data-aos="fade-up" data-aos-duration="1000" className="w-28 h-28" src={alert} alt="Manage Alerts" />
            <h1 data-aos="fade-up" data-aos-duration="1000" className="text-lg font-semibold pt-6 text-center">
              Manage Alerts
            </h1>
            <p data-aos="fade-up" data-aos-duration="1000" className="text-sm pt-3 text-center">
              Stay updated with <br /> important alerts.
            </p>
          </div>

          {/* Puzzle Card */}
          <div className="bg-yellow-100 h-80 md:h-96 w-64 md:w-72 rounded-2xl shadow-xl flex flex-col items-center justify-center transition-transform transform hover:scale-105">
            <img data-aos="fade-up" data-aos-duration="1000" className="w-28 h-28" src={puzzle} alt="Manage Tasks" />
            <h1 data-aos="fade-up" data-aos-duration="1000" className="text-lg font-semibold pt-6 text-center">
              Manage Tasks
            </h1>
            <p data-aos="fade-up" data-aos-duration="1000" className="text-sm pt-3 text-center">
              Stay organized with <br /> your tasks.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Manage;
