import React from "react";
import board from '../assets/board.svg';
import folder from '../assets/folder.svg';
import mail from '../assets/mail.svg';
import board1 from '../assets/board1.svg';

const Options = () => {
  return (
    <section id="features" className="mt-12 font-poppins">
      <h1 data-aos="fade-up" data-aos-duration="1000" className="text-center text-xl font-semibold">No difficulty at all in</h1>
      <p data-aos="fade-up" data-aos-duration="1000" className="text-center mt-7 text-2xl font-bold">Managing sponsored employees</p>
      <div data-aos="fade-up" data-aos-duration="1000" className="border-b-4 border-yellow-500 w-36 mx-auto"></div>
      <button className="mt-9 md:w-80 lg:w-96 text-gray-400 rounded-xl h-12 md:h-14 lg:h-16 bg-yellow-400 mx-auto block">Start onboarding by collecting all needed information.</button>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-12 gap-y-20 mt-12 md:px-40">
        <div className="bg-yellow-100 border border-yellow-200 h-96 w-full md:w-96 rounded-2xl shadow-xl mx-auto flex flex-col items-center">
          <img data-aos="fade-up" data-aos-duration="1000" className="pt-6" src={board} alt="Board" />
          <h1 data-aos="fade-up" data-aos-duration="1000" className="text-lg font-semibold pt-6">1. Onboard</h1>
          <p data-aos="fade-up" data-aos-duration="1000" className="text-sm pt-3 text-center px-4">Start onboarding by collecting all needed information.</p>
        </div>

        <div className="bg-yellow-100 border border-yellow-200 h-96 w-full md:w-96 rounded-2xl shadow-xl mx-auto flex flex-col items-center">
          <img data-aos="fade-up" data-aos-duration="1000" className="pt-6" src={folder} alt="Upload" />
          <h1 data-aos="fade-up" data-aos-duration="1000" className="text-lg font-semibold pt-6">2. Upload</h1>
          <p data-aos="fade-up" data-aos-duration="1000" className="text-sm pt-3 text-center px-4">Collect and upload all mandatory documents.</p>
        </div>

        <div className="bg-yellow-100 border border-yellow-200 h-96 w-full md:w-96 rounded-2xl shadow-xl mx-auto flex flex-col items-center">
          <img data-aos="fade-up" data-aos-duration="1000" className="pt-6" src={mail} alt="Verify" />
          <h1 data-aos="fade-up" data-aos-duration="1000" className="text-lg font-semibold pt-6">3. Verify</h1>
          <p data-aos="fade-up" data-aos-duration="1000" className="text-sm pt-3 text-center px-4">Do Right to work check and record outcomes.</p>
        </div>

        <div className="bg-yellow-100 border border-yellow-200 h-96 w-full md:w-96 rounded-2xl shadow-xl mx-auto flex flex-col items-center">
          <img data-aos="fade-up" data-aos-duration="1000" className="pt-6" src={board1} alt="Relax" />
          <h1 data-aos="fade-up" data-aos-duration="1000" className="text-lg font-semibold pt-6">4. Relax</h1>
          <p data-aos="fade-up" data-aos-duration="1000" className="text-sm pt-3 text-center px-4">System will monitor the key data & notify in advance.</p>
        </div>
      </div>
    </section>
  );
};

export default Options;
