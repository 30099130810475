import React from 'react';

const JobModal = ({ isOpen, onClose, onSubmit, formData, handleChange }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed overflow-y-auto inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded shadow-lg w-1/2">
                <h2 className="text-xl font-bold mb-4">Role Information</h2>
                <form onSubmit={onSubmit}>
                    <input type="hidden" name="employee_id" value={formData.employee_id} />
                    
                    <input
                        type="text"
                        name="job_title"
                        placeholder="Job Title"
                        value={formData.job_title}
                        onChange={handleChange}
                        required
                        className="border p-2 mb-2 w-full"
                    />

                    <input
                        type="text"
                        name="contract_type"
                        placeholder="Contract Type"
                        value={formData.contract_type}
                        onChange={handleChange}
                        required
                        className="border p-2 mb-2 w-full"
                    />

                    <input
                        type="text"
                        name="team"
                        placeholder="Team"
                        value={formData.team}
                        onChange={handleChange}
                        required
                        className="border p-2 mb-2 w-full"
                    />

                    <input
                        type="text"
                        name="report_to"
                        placeholder="Report To"
                        value={formData.report_to}
                        onChange={handleChange}
                        required
                        className="border p-2 mb-2 w-full"
                    />

                    <select
                        name="probation_required"
                        value={formData.probation_required}
                        onChange={handleChange}
                        required
                        className="border p-2 mb-2 w-full"
                    >
                        <option value="" disabled>
                            Probation Required
                        </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>

                    <input
                        type="date"
                        name="probation_end_date"
                        placeholder="Probation End Date"
                        value={formData.probation_end_date}
                        onChange={handleChange}
                        className="border p-2 mb-2 w-full"
                    />

                    <input
                        type="text"
                        name="notice_required_during_probation"
                        placeholder="Notice Required During Probation"
                        value={formData.notice_required_during_probation}
                        onChange={handleChange}
                        required
                        className="border p-2 mb-2 w-full"
                    />

                    <input
                        type="text"
                        name="notice_period"
                        placeholder="Notice Period"
                        value={formData.notice_period}
                        onChange={handleChange}
                        required
                        className="border p-2 mb-2 w-full"
                    />

                    <div className="flex justify-between mt-4">
                        <button
                            type="button"
                            onClick={onClose}
                            className="bg-gray-300 text-black p-2 rounded hover:bg-gray-400"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600"
                        >
                            {formData.id ? 'Update Contract' : 'Add Contract'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default JobModal;
