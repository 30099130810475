import React from 'react'
import Sidebar from '../Sidebar'
import Navbar from '../Navbar'
import CourseManager from './Course'


const Cou = () => {
  return (
	<>
	
	<section className="flex">
      <Sidebar />
      <div className="flex-1">
        <Navbar />
  <CourseManager/>
      </div>
    </section>
	</>
  )
}

export default Cou