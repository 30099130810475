export const links = [
	{ name: 'Home', url: '/dashboard', icon: '/img/home.svg' },
	{ name: 'Employees', url: '/org', icon: '/img/employe.svg' },
	{ name: 'Calender', url: '/calender', icon: '/img/calender.svg' },

	{ name: 'Documents', url: '/documents', icon: '/img/document.svg' },
	{ name: 'E-Learning', url: '/e-learning', icon: '/img/learning.svg' },
	
	{ name: 'Payroll', url: '/payroll', icon: '/img/pay.svg' },
	{ name: 'Expenses', url: '/expenses', icon: '/img/expenses.svg' },
	{ name: 'Data protection', url: '/data', icon: '/img/data.svg' },
]
export const link = (id) => [
	{ name: 'Absense', url: `/emp/${id}` },
	{ name: 'Employment', url: `/employee/${id}` },
	{ name: 'Tasks', url: `/time-management/${id}` },
	{ name: 'Overtime', url: `/time-overtime/${id}` }, // Adjust as needed
	{ name: 'Personal', url: `/personal/${id}` }, // Adjust as needed
	{ name: 'Emergencies', url: `/emer/${id}` }, // Adjust as needed
	{ name: 'Timesheet', url: `/timesheet/${id}` }, // Adjust as needed
	{ name: 'Documents', url: `/documents/${id} ` }, // Adjust as needed
	{ name: 'Clock', url: `/task/ ` }, // Adjust as needed
  ];