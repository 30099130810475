import React from 'react';
import icons from '../assets/icons.svg';

const Footer = () => {
  return (
    <section className="mt-12 font-poppins">
      {/* Newsletter Subscription Button */}
      <div
        data-aos="fade-up"
        data-aos-duration="1000"
        className="flex items-center justify-center"
      >
        <button className="bg-yellow-400 h-12 w-full max-w-lg font-bold rounded-xl text-yellow-300">
          Subscribe to our Newsletter!
        </button>
      </div>

      {/* Search Bar Section */}
      <div
        data-aos="fade-up"
        data-aos-duration="1000"
        className="flex flex-col sm:flex-row justify-center items-center mt-6 space-y-4 sm:space-y-0"
      >
        <input
          id="input"
          className="h-12 w-full max-w-lg sm:w-3/5 placeholder:pl-4 sm:rounded-l-3xl rounded-xl bg-yellow-400 sm:rounded-none"
          type="text"
          placeholder="Enter your email"
        />
        <button className="bg-yellow-300 text-white font-bold h-12 w-full max-w-sm sm:w-40 sm:rounded-r-3xl sm:rounded-none sm:mt-0 mt-4 sm:ml-[-8px] rounded-xl">
          Subscribe
        </button>
      </div>

      {/* Footer Links Section */}
      <div className="flex flex-col sm:flex-row items-center justify-between mt-16 space-y-6 sm:space-y-0 px-4 sm:px-10">
        <div className="flex space-x-8">
          <h1 className="text-lg">About</h1>
          <h1 className="text-lg">Mail</h1>
          <h1 className="text-lg">Number</h1>
        </div>
        <img src={icons} alt="Icons" className="w-32 sm:w-auto" />
      </div>
    </section>
  );
};

export default Footer;
