import React, { useState } from 'react';
import { BsSearch } from 'react-icons/bs';
import { GiHamburgerMenu } from 'react-icons/gi';
import { AiOutlineClose } from 'react-icons/ai';
import logo from '../assets/logo.svg';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  const toggleModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  const handleSignup = () => {
    navigate('/signup');
  };

  const handleLogin = () => {
    navigate('/login');
  };

  const handleAddEmployee = () => {
    console.log('Add employee logic here');
    setIsModalOpen(false);
  };

  return (
    <div className="flex flex-col md:flex-row items-center justify-between p-4 relative">
      <div className="flex items-center w-full md:w-auto">
        <img src={logo} alt="Logo" className="h-10" />
        {/* Search Bar */}
        <div className="flex-grow max-w-md ml-4  md:ml-11">
          <form className="flex border rounded-2xl overflow-hidden bg-blue-100 shadow-lg w-[500px] max-sm:w-24">
            <input
              type="text"
              className="px-4 py-2 bg-white w-full focus:outline-none"
              placeholder="Search..."
            />
            <button
              type="submit"
              className="text-white px-4 py-2 flex items-center justify-center bg-blue-500 hover:bg-blue-600"
            >
              <BsSearch color="white" />
            </button>
          </form>
        </div>
      </div>

      {/* Add Button and Menu Icon */}
      <div className="flex items-center space-x-4 mt-4 md:mt-0">
        <button
          onClick={toggleModal}
          className="bg-blue-200 h-9 w-44 rounded-lg text-white font-medium flex items-center justify-center"
        >
          Add Employees
        </button>
        <button className="flex items-center" onClick={toggleMenu}>
          <GiHamburgerMenu className="h-8 w-8" color="rgba(0, 161, 0, 1)" />
        </button>
      </div>

      {/* Dropdown Menu */}
      {isMenuOpen && (
        <div className="absolute top-0 right-0 w-64 h-screen bg-white shadow-lg z-50">
          {/* Close Button */}
          <button
            onClick={toggleMenu}
            className="absolute top-4 right-4 text-2xl text-gray-600"
          >
            <AiOutlineClose />
          </button>

          <div className="flex flex-col items-center mt-20 space-y-6">
            <button
              onClick={handleSignup}
              className="bg-blue-500 text-white py-2 px-4 w-40 rounded-lg hover:bg-blue-600 transition duration-200"
            >
              Signup
            </button>
            <button
              onClick={handleLogin}
              className="bg-green-500 text-white py-2 px-4 w-40 rounded-lg hover:bg-green-600 transition duration-200"
            >
              Login
            </button>
          </div>
        </div>
      )}

      {/* Modal for Adding Employee */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-8 w-full max-w-lg">
            <h2 className="text-2xl font-bold mb-4">Add Employee</h2>

            {/* Employee Form */}
            <form>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Employee Name</label>
                <input
                  type="text"
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Enter name"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Email</label>
                <input
                  type="email"
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Enter email"
                />
              </div>

              <div className="flex justify-end space-x-4">
                <button
                  type="button"
                  className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition"
                  onClick={toggleModal}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition"
                  onClick={handleAddEmployee}
                >
                  Add Employee
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;