import React, { useState, useEffect } from 'react'; 
import axios from 'axios';

const Org = () => {
    const [employees, setEmployees] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // Fetch employees based on the company from localStorage
    const fetchEmployees = async (company) => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get(`https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/read_users.php?company=${company}`);
            if (Array.isArray(response.data)) {
                setEmployees(response.data);
            } else {
                setError('Unexpected data format.');
            }
        } catch (err) {
            setError('Failed to fetch employees. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    // UseEffect to fetch employees on component mount
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.company) {
            fetchEmployees(user.company);
        }
    }, []);

    return (
       
        <div className="org-container max-w-7xl mx-auto p-4 font-poppins">
             <style>
            {`
                #text{
                     	 text-shadow: 1px 1px gray;}`}
        </style>
                           <h1 id='text' className='font-poppins text-5xl text-center  drop-shadow-lg font-bold mb-6 tracking-wide'>Organization Employees</h1>
            {loading && <p className="text-center">Loading...</p>}
            {error && <p className="text-red-500 text-center">{error}</p>}
            {employees.length === 0 && !loading && !error && (
                <p className="text-gray-500 text-center">No employees found.</p>
            )}

            <ul className="employee-list grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                {employees.map((employee) => (
                    <li key={employee.id} className="bg-white shadow-md rounded-lg p-4 transition-transform transform hover:scale-105">
                        <div className="infotop space-y-4 text-center">
                            {employee.profile_pic ? (
                                <img
                                    src={employee.profile_pic}
                                    alt={`${employee.first_name} ${employee.last_name}`}
                                    className='rounded-full h-20 w-20 mx-auto'
                                />
                            ) : (
                                <div className="profile-pic mx-auto">
                                    {employee.gender === 'male' ? (
                                        <img 
                                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnjj6PeSIOKEaRUwTfPz5JX9UwjhVbn5sE-Q&s" 
                                            alt="Default Male" 
                                            className="rounded-full justify-center mx-auto h-20 w-20" 
                                        />
                                    ) : (
                                        <img 
                                            src="https://i0.wp.com/cgdental.com.au/wp-content/uploads/2022/03/CG-Dental-Female-Avatar-01.jpg?ssl=1" 
                                            alt="Default Female" 
                                            className="rounded-full justify-center mx-auto  h-20 w-20" 
                                        />
                                    )}
                                </div>
                            )}
                            <h2 className='mt-2 text-lg font-semibold'>{employee.first_name} {employee.last_name}</h2>
                            <p className="text-gray-600">Email: {employee.email}</p>
                            <p className="text-gray-600">Department: {employee.department}</p>
                            <p className="text-gray-600">Role: {employee.role}</p>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Org;
