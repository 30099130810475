import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { IoMdMail } from 'react-icons/io';
import { NavLink, useParams } from 'react-router-dom';
import { link } from '../constants/sidebar';
import Sidebar from './Sidebar';

const Time = () => {
    const { id } = useParams(); // Get the id from the URL parameters
    const user = JSON.parse(localStorage.getItem('user'));
    const [userId] = useState(id);

 
  const links = link(id); 
    const [taskName, setTaskName] = useState('');
    const [startTime, setStartTime] = useState('');
    const [message, setMessage] = useState('');
    const [taskDetails, setTaskDetails] = useState([]);
    const [elapsedTime, setElapsedTime] = useState(0);
    const [currentTaskId, setCurrentTaskId] = useState(null);
    const [isRunning, setIsRunning] = useState(false);
    const [totalTime, setTotalTime] = useState(0);

    // Fetch tasks and total time when component mounts
    useEffect(() => {
        const fetchTasks = async () => {
            const response = await fetch('https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/task/fetch_tasks.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_id: userId }),
            });

            const data = await response.json();

            if (data.status === 'success') {
                setTaskDetails(data.tasks);
                setTotalTime(data.total_time); // Set total time
            } else {
                setMessage(data.message);
            }
        };

        fetchTasks();
    }, [userId]);

    // Set the current date and time when the component mounts
    useEffect(() => {
        const now = new Date();
        const formattedDateTime = now.toISOString().slice(0, 16);
        setStartTime(formattedDateTime);
    }, []);

    // Timer logic
    useEffect(() => {
        let interval = null;
        if (isRunning) {
            interval = setInterval(() => {
                setElapsedTime((prevTime) => prevTime + 1);
            }, 1000);
        } else if (!isRunning && elapsedTime !== 0) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [isRunning, elapsedTime]);

    const handleCreateTask = async () => {
        if (!taskName || !startTime) {
            setMessage('Task name and start time are required.');
            return;
        }

        const response = await fetch('https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/task/create_task.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ user_id: userId, task_name: taskName, start_time: startTime }),
        });

        const data = await response.json();

        if (data.status === 'success') {
            setTaskDetails((prevTasks) => [
                ...prevTasks,
                {
                    task_name: taskName,
                    start_time: startTime,
                    stop_time: null,
                    id: data.time_log_id,
                },
            ]);
            setTaskName('');
            setMessage('Task created successfully.');
        } else {
            setMessage(data.message);
        }
    };

    const handleStartTask = (taskId) => {
        if (isRunning) {
            setMessage('A task is already running. Stop it before starting a new one.');
            return;
        }

        const task = taskDetails.find(task => task.id === taskId);
        if (!task) return;

        setIsRunning(true);
        setCurrentTaskId(taskId);
        setElapsedTime(0); // Reset elapsed time for the new task
    };

    const handleStopTask = async (taskId) => {
        try {
            const response = await fetch('https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/task/stop_task.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ time_log_id: taskId }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();

            if (result.status === 'success') {
                setTaskDetails((prevTasks) => {
                    return prevTasks.map((task) => {
                        if (task.id === taskId) {
                            return {
                                ...task,
                                stop_time: new Date().toISOString(),
                            };
                        }
                        return task;
                    });
                });
                setIsRunning(false);
                setCurrentTaskId(null);
                setElapsedTime(0); // Reset elapsed time
                setMessage('Task stopped successfully.');
                // Re-fetch tasks to update total time
                
            } else {
                console.error('Error stopping task:', result.message);
            }
        } catch (error) {
            console.error('Error in stopping task:', error);
        }
    };

    const handleDeleteTask = async (taskId) => {
        try {
            const response = await fetch('https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/task/delete_tasks.php', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ time_log_id: taskId }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();

            if (result.status === 'success') {
                setTaskDetails((prevTasks) => prevTasks.filter(task => task.id !== taskId));
                console.log(result.message);
                // Update total time
                setTotalTime((prevTotal) => prevTotal - taskDetails.find(task => task.id === taskId).duration);
            } else {
                console.error('Error deleting task:', result.message);
            }
        } catch (error) {
            console.error('Error in deleting task:', error);
        }
    };
    const { id: employeeId } = useParams();
	
    const [employee, setEmployee] = useState(null);
  
  useEffect(() => {
	const fetchEmployee = async () => {
	  try {
		const response = await axios.get(`https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/read_users.php?id=${employeeId}`);
		setEmployee(response.data);
	  } catch (err) {
		console.error("Error fetching employee details:", err);
	  }
	};

	fetchEmployee();
  }, [employeeId]);

    return (
        <section className="flex ">
           <div className="w-1/5 ">
        <Sidebar />
      </div>
        <div className="
        ">
            {employee ? (
  <div className="flex flex-col md:flex-row ml-6 mt-10 bg-blue-100 h-auto p-5 rounded-3xl items-center  justify-center">
    <div className="text-7xl border h-36 w-36 p-1 rounded-full bg-white flex items-center justify-center">
      {employee.profile_pic ? (
        <img src={employee.profile_pic} alt="" className="rounded-full h-36 w-36" />
      ) : (
        <span className="rounded-full text-gray-600 font-bold text-4xl">
          {employee.first_name.charAt(0)}{employee.last_name.charAt(0)}
        </span>
      )}
    </div>
    <div className="ml-0 md:ml-8 mt-4 flex md:mt-0 text-center md:text-left">
      <div className="">
	  <h1 className="text-2xl font-bold">{employee.first_name} {employee.last_name}</h1>
      
      <p className="text-gray-600 mb-1 flex justify-center md:justify-start gap-3">
        <strong>Department:</strong> {employee.department}
      </p>
      <p className="text-gray-600 flex justify-center md:justify-start gap-3">
        <strong>Role:</strong> {employee.role}
      </p>
	  </div>
	  <div className="flex justify-end items-center pl-72">
	  <p className="text-gray-600 mb-1 flex justify-center md:justify-start gap-3">
        <strong>                <IoMdMail color="green" /> </strong> {employee.email}
      </p>
   
	  </div>
    </div>
  </div>
) : (
  <div>Employee not found</div>
)}
  <ul className='flex gap-16 ml-6 text-lg p-4 bg-blue-300 mt-8 rounded-t-2xl h-12'>
      {links.map((linkItem, index) => (
        <li key={index}>
          <NavLink
            to={linkItem.url}
            className={({ isActive }) =>
              isActive ? "text-blue-200 border-t-2 font-bold" : "text-gray-700"
            }
            end
          >
            {linkItem.name}
          </NavLink>
        </li>
      ))}
    </ul>
        <div className="container mx-auto p-6 bg-white rounded-lg shadow-md">
            <h1 className="text-3xl font-semibold text-center mb-6">Task Manager</h1>
            <div className="flex flex-col items-center mb-4">
                {user.role === 'hr' && (
                    <>
                        <input
                            type="text"
                            placeholder="Task Name"
                            value={taskName}
                            onChange={(e) => setTaskName(e.target.value)}
                            className="border border-gray-300 rounded-md p-3 mb-4 w-full max-w-md"
                        />
                        <input
                            type="datetime-local"
                            value={startTime}
                            onChange={(e) => setStartTime(e.target.value)}
                            className="border border-gray-300 rounded-md p-3 mb-4 w-full max-w-md"
                        />
                        <button
                            onClick={handleCreateTask}
                            className="bg-blue-600 text-white font-semibold p-3 rounded-md transition duration-300 hover:bg-blue-500 w-full max-w-md mb-4"
                        >
                            Create Task
                        </button>
                        <h2 className="text-xl font-semibold">Total Time Worked: {Math.floor(totalTime / 60)}h {totalTime % 60}m</h2>
                    </>
                )}
                {user.role !== 'hr' && (
                    <p className="text-red-500">You do not have permission to create tasks.</p>
                )}
            </div>
            <div>
                <h2 className="text-xl font-semibold">Task List</h2>
                <ul className="list-disc pl-5">
                    {taskDetails.map((task) => (
                        <li key={task.id} className="mt-2 flex justify-between items-center">
                            <div>
                                <strong>{task.task_name}</strong> - Started at: {task.start_time}
                                {task.stop_time ? ` - Stopped at: ${task.stop_time}` : " - Running..."}
                            </div>
                            <div className="ml-4">
                                {/* Show Start button only if the task is not running and user is HR */ }
                                {task.stop_time && user.role === 'hr' && (
                                    <button
                                        onClick={() => handleStartTask(task.id)}
                                        className="bg-green-600 text-white font-semibold p-2 rounded-md mr-2 transition duration-300 hover:bg-green-500"
                                    >
                                        Start
                                    </button>
                                )}
                                {/* Show Stop button only if the task is currently running */ }
                                {!task.stop_time && currentTaskId === task.id && (
                                    <button
                                        onClick={() => handleStopTask(task.id)}
                                        className="bg-red-600 text-white font-semibold p-2 rounded-md mr-2 transition duration-300 hover:bg-red-500"
                                    >
                                        Stop
                                    </button>
                                )}
                                <button
                                    onClick={() => handleDeleteTask(task.id)}
                                    className="bg-red-600 text-white font-semibold p-2 rounded-md transition duration-300 hover:bg-red-500"
                                >
                                    Delete
                                </button>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
            {isRunning && (
                <div className="mt-4">
                    <h2 className="text-xl font-semibold">Current Timer</h2>
                    <div className="flex items-center justify-center mt-2">
                        <span className="text-4xl">{`${String(Math.floor(elapsedTime / 3600)).padStart(2, '0')}:${String(Math.floor((elapsedTime % 3600) / 60)).padStart(2, '0')}:${String(elapsedTime % 60).padStart(2, '0')}`}</span>
                    </div>
                </div>
            )}
            {message && <p className="mt-4 text-red-500">{message}</p>}
        </div>
        </div>
        </section>
    );
};

export default Time;
