import React, { useState, useEffect } from "react";
import { FaFilePdf } from "react-icons/fa"; // Icon for the PDF export button
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
const Reports = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showExEmployees, setShowExEmployees] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [sortOrder, setSortOrder] = useState("asc");
  const [formData, setFormData] = useState({
    name: "",
    department: "",
    dateSubmitted: "",
    dateApproved: "",
    approver: "",
    net: "",
    vat:  "",
    gross:  "",
    paid:  "",
    id: null,
  });
  
  const user = JSON.parse(localStorage.getItem('user'));
  const company = user.company;

  useEffect(() => {
    fetchReports();
    tippy('[data-tippy-content]', { theme: 'light', delay: [200, 0] });
  }, []);

  const fetchReports = async () => {
    try {
      const response = await fetch(`https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/reports/get_reports.php?company=${company}`, {
        method: "GET",
        credentials: "include", // Ensure cookies are sent for session
      });
      const data = await response.json();
      setTableData(data);
    } catch (error) {
      console.error("Error fetching reports:", error);
    }
  };

  const handleSort = () => {
    const sortedData = [...tableData].sort((a, b) => {
      return sortOrder === "asc" ? a.gross - b.gross : b.gross - a.gross;
    });
    setTableData(sortedData);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleInputChange = (key, value) => {
    setFormData({ ...formData, [key]: value });
  };

  const handleAddOrUpdateReport = async (e) => {
    e.preventDefault();
    const method = formData.id ? "PUT" : "POST";
    const url = formData.id 
      ? `https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/reports/update_report.php?id=${formData.id}&company=${encodeURIComponent(company)}`
      : `https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/reports/create_report.php?company=${encodeURIComponent(company)}`;
  
    try {
      await fetch(url, {
        method: method,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
        credentials: "include",
      });
      fetchReports();
      resetForm();
    } catch (error) {
      console.error("Error saving report:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await fetch(`https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/reports/delete_report.php?id=${id}`, {
        method: "DELETE",
        credentials: "include",
      });
      fetchReports();
    } catch (error) {
      console.error("Error deleting report:", error);
    }
  };

  const resetForm = () => {
    setFormData({
      name: "",
      department: "",
      dateSubmitted: "",
      dateApproved: "",
      approver: "",
      net: 0,
      vat: 0,
      gross: 0,
      paid: 0,
      id: null,
    });
  };

  const handleEdit = (report) => {
    setFormData(report);
  };

  const exportToPDF = () => {
    const input = document.getElementById("table-section");
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF();
        pdf.addImage(imgData, "PNG", 10, 10, 190, 0);
        pdf.save("reports.pdf");
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
      });
  };
  tippy('#button', {
    content: "I'm a Tippy tooltip!",
  });
  return (
    <div className="p-6">
      <div className="flex flex-wrap gap-4 mb-6">
        <label className="flex items-center space-x-2">
          <input
            type="checkbox"
            className="h-5 w-5 text-green-600 rounded focus:ring focus:ring-green-500"
            onChange={() => setShowExEmployees(!showExEmployees)}
            checked={showExEmployees}
          />
          <span className="text-gray-700 font-medium">Show ex employees</span>
        </label>
      </div>

      <div className="flex items-center mb-4 space-x-2">
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearch}
          placeholder='Search "Luke Daniel Johnson"'
          className="flex-1 p-3 border border-gray-300 rounded-l-lg focus:outline-none focus:ring-2 focus:ring-green-500"
        />
        <button className="bg-green-500 text-white font-medium py-3 px-6 rounded-r-lg hover:bg-green-600 transition">
          Search
        </button>
        <button 
        onClick={exportToPDF} 
        data-tippy-content="Click to export reports data"
        className=" bg-green-500 h-12 text-white font-medium py-2 px-4 mb-4 rounded align-middle mt-4  hover:bg-green-600 transition flex items-center space-x-2"
      >
        <FaFilePdf />
        <span>Export to PDF</span>
      </button>
      </div>

      

      <div id="table-section" className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-300 rounded-lg shadow-lg">
          <thead>
          <tr className="bg-green-100 text-left">
              <th className="p-4 text-gray-600 font-medium" data-tippy-content="Employee's full name">Name</th>
              <th className="p-4 text-gray-600 font-medium" data-tippy-content="Department where the employee works">Department</th>
              <th className="p-4 text-gray-600 font-medium" data-tippy-content="Date the report was submitted">Date Submitted</th>
              <th className="p-4 text-gray-600 font-medium" data-tippy-content="Date the report was approved">Date Approved</th>
              <th className="p-4 text-gray-600 font-medium" data-tippy-content="Name of the person who approved the report">Approver</th>
              <th className="p-4 text-gray-600 font-medium" data-tippy-content="Net amount">Net</th>
              <th className="p-4 text-gray-600 font-medium" data-tippy-content="Value-added tax amount">VAT</th>
              <th className="p-4 text-gray-600 font-medium cursor-pointer" onClick={handleSort} data-tippy-content="Gross amount. Click to sort">
                Gross {sortOrder === "asc" ? "↑" : "↓"}
              </th>
              <th className="p-4 text-gray-600 font-medium" data-tippy-content="Amount paid to the employee">Paid</th>
              <th className="p-4 text-gray-600 font-medium" data-tippy-content="Actions to edit or delete the report">Actions</th>
            </tr>
          </thead>
          <tbody>
            {tableData
              .filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()))
              .map((item) => (
                <tr key={item.id} className="border-t hover:bg-gray-100 transition">
                  <td className="p-4">{item.name}</td>
                  <td className="p-4">{item.department}</td>
                  <td className="p-4">{item.dateSubmitted}</td>
                  <td className="p-4">{item.dateApproved}</td>
                  <td className="p-4">{item.approver}</td>
                  <td className="p-4">
                    <input type="number" value={item.net} readOnly className="border rounded p-1 w-16" />
                  </td>
                  <td className="p-4">
                    <input type="number" value={item.vat} readOnly className="border rounded p-1 w-16" />
                  </td>
                  <td className="p-4">
                    <input type="number" value={item.gross} readOnly className="border rounded p-1 w-16" />
                  </td>
                  <td className="p-4">
                    <input type="number" value={item.paid} readOnly className="border rounded p-1 w-full" />
                  </td>
                  <td className="p-4 flex space-x-2">
                    <button onClick={() => handleEdit(item)} className="bg-blue-500 text-white py-1 px-2 rounded hover:bg-blue-600 transition">Edit</button>
                    <button onClick={() => handleDelete(item.id)} className="bg-red-500 text-white py-1 px-2 rounded hover:bg-red-600 transition">Delete</button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <form onSubmit={handleAddOrUpdateReport} className="mt-6 bg-white p-4 rounded-lg shadow">
        <h2 className="text-lg font-semibold mb-4">{formData.id ? 'Edit Report' : 'Add Report'}</h2>
        <div className="grid grid-cols-2 gap-4">
          <input type="text" placeholder="Name" value={formData.name} onChange={(e) => handleInputChange("name", e.target.value)} className="border border-gray-300 p-2 rounded"  data-tippy-content="Employee full name" />
          <input type="text" placeholder="Department" value={formData.department} onChange={(e) => handleInputChange("department", e.target.value)} className="border border-gray-300 p-2 rounded" data-tippy-content="Employee deparatment" />
          <input type="date" placeholder="Date Submitted" value={formData.dateSubmitted} onChange={(e) => handleInputChange("dateSubmitted", e.target.value)} className="border border-gray-300 p-2 rounded" data-tippy-content="Date submitted"/>
          <input type="date" placeholder="Date Approved" value={formData.dateApproved} onChange={(e) => handleInputChange("dateApproved", e.target.value)} className="border border-gray-300 p-2 rounded" data-tippy-content="Date approved" />
          <input type="text" placeholder="Approver" value={formData.approver} onChange={(e) => handleInputChange("approver", e.target.value)} className="border border-gray-300 p-2 rounded" data-tippy-content="Date approved" />
          <input type="number" placeholder="Net" value={formData.net} onChange={(e) => handleInputChange("net", e.target.value)} className="border border-gray-300 p-2 rounded" data-tippy-content="Net amount" />
          <input type="number" placeholder="VAT" value={formData.vat} onChange={(e) => handleInputChange("vat", e.target.value)} className="border border-gray-300 p-2 rounded" data-tippy-content="Value-added tax amount"/>
          <input type="number" placeholder="Gross" value={formData.gross} onChange={(e) => handleInputChange("gross", e.target.value)} className="border border-gray-300 p-2 rounded" data-tippy-content="Gross amount" />
          <input type="number" placeholder="Paid to Employee" value={formData.paid} onChange={(e) => handleInputChange("paid", e.target.value)} className="border border-gray-300 p-2 rounded" data-tippy-content="Total pay" />
        </div>
        <button type="submit" className="mt-4 bg-green-500 text-white py-2 px-6 rounded hover:bg-green-600 transition">
          {formData.id ? 'Update Report' : 'Add Report'}
        </button>
      </form>
    </div>
  );
};

export default Reports;
