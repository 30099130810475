import React from 'react'
import Sidebar from '../Sidebar'
import Navbar from '../Navbar'
import DocumentUpload from './DocumentUpload'


const Documentt = () => {
  return (
	<>
	
	<section className="flex font-poppins">
      <Sidebar />
      <div className="flex-1">
        <Navbar />

<DocumentUpload/>
      </div>
    </section>
	</>
  )
}

export default Documentt