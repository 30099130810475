import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Modal from "./Modal"; // Modal component for adding/editing contracts
import { toast } from "react-toastify"; // Ensure correct import
import "react-toastify/dist/ReactToastify.css";

const Contracts = () => {
  const { id: employeeId } = useParams();
  const user = JSON.parse(localStorage.getItem("user"));
  const [contracts, setContracts] = useState([]);
  
  const [formData, setFormData] = useState({
    id: null,
    employee_id: employeeId,
    entitlement_unit: "",
    annual_leave_balance: "",
    contract_start_date: "",
    employment_type: "",
    working_time_pattern: "",
    contracted_hours_per_week: "",
    annual_leave_year_start: "",
    min_leave_entitlement: "",
    working_location: "",
    address: "",
    public_holidays: "",
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false); // New state for delete modal
  const [contractToDelete, setContractToDelete] = useState(null); // Track the contract to be deleted
  const [isSubmitting, setIsSubmitting] = useState(false); // State for handling submission

  // Fetch contracts from the backend
  useEffect(() => {
    const fetchContracts = async () => {
      try {
        const response = await axios.get(`https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/read_contracts.php?employee_id=${employeeId}`);
        setContracts(response.data);
      } catch (error) {
        console.error("Error fetching contracts:", error);
      }
    };

    fetchContracts();
  }, [employeeId]);

  // Handle form input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle form submission (add/edit contract)
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return; // Prevent duplicate submissions
    setIsSubmitting(true); // Disable button

    try {
      if (formData.id) {
        // Update existing contract
        const response = await axios.post("https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/update_contract.php", formData);
        console.log(response.data.message);
        toast.success("Update successful!", { position: "top-right" });
        setContracts(
          contracts.map((contract) =>
            contract.id === formData.id ? { ...contract, ...formData } : contract
          )
        );
      } else {
        // Add new contract
        const response = await axios.post("https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/add_contract.php", formData);
        console.log(response.data.message);
        toast.success("Contract added successfully!", { position: "top-right" });
        setContracts([...contracts, { ...formData, id: response.data.id }]);
      }
      setIsModalOpen(false);
      resetFormData();
    } catch (error) {
      console.error("Error saving contract:", error);
      toast.error("Error occurred while saving contract.", { position: "top-right" });
    } finally {
      setIsSubmitting(false); // Re-enable button
    }
  };

  // Handle contract deletion confirmation modal
  const openDeleteModal = (contractId) => {
    setContractToDelete(contractId);
    setIsDeleteModalOpen(true);
  };

  const confirmDelete = async () => {
    try {
      const response = await axios.post("https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/delete_contract.php", { contract_id: contractToDelete });
      console.log(response.data.message);
      toast.success("Contract deleted successfully!", { position: "top-right" });
      setContracts(contracts.filter((contract) => contract.id !== contractToDelete));
      setIsDeleteModalOpen(false);
      setContractToDelete(null);
    } catch (error) {
      console.error("Error deleting contract:", error);
      toast.error("Error occurred while deleting contract.", { position: "top-right" });
    }
  };

  // Close the delete modal
  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setContractToDelete(null);
  };

  // Handle edit action (pre-fill form data)
  const handleEdit = (contract) => {
    setFormData(contract);
    setIsModalOpen(true);
  };

  const resetFormData = () => {
    setFormData({
      id: null,
      employee_id: employeeId,
      entitlement_unit: "",
      annual_leave_balance: "",
      contract_start_date: "",
      employment_type: "",
      working_time_pattern: "",
      contracted_hours_per_week: "",
      annual_leave_year_start: "",
      min_leave_entitlement: "",
      working_location: "",
      address: "",
      public_holidays: "",
    });
  };

  return (
    <>
{/* Add ToastContainer here */}
      <div className="font-poppins">
        <h1 className="text-xl font-bold pl-5 pt-7">Contract and annual leave information</h1>
        <p className="mb-6 pl-5 w-96 text-base">Contracted hours of work, employment start date and leave entitled</p>

        {contracts.length > 0 ? (
          contracts.map((contract) => (
            <div key={contract.id} className="mb-4 p-4 bg-gray-100 w-full rounded-lg shadow-sm">
              <div className="flex justify-end items-center mb-2">
                {user.role === "hr" && (
                  <div className="flex justify-end items-center mb-2">
                    <div className="flex space-x-2">
                      <button onClick={() => handleEdit(contract)} className="text-yellow-500 hover:underline">
                        Edit
                      </button>
                      <button onClick={() => openDeleteModal(contract.id)} className="text-red-500 hover:underline">
                        Delete
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <div className="grid gap-4">
                <div className="flex">
                  <h3 className="font-medium min-w-[200px]">Entitlement Unit:</h3>
                  <p className="flex-1">{contract.entitlement_unit || "N/A"}</p>
                </div>
                <div className="flex">
                  <h3 className="font-medium min-w-[200px]">Annual Leave Balance:</h3>
                  <p className="flex-1">{contract.annual_leave_balance || "N/A"}</p>
                </div>
                <h1 className="text-lg font-poppins font-medium">Contract Summary</h1>
                <div className="flex">
                  <h3 className="font-medium min-w-[200px]">Employment Type:</h3>
                  <p className="flex-1">{contract.employment_type || "N/A"}</p>
                </div>
                <div className="flex">
                  <h3 className="font-medium min-w-[200px]">Contract Start Date:</h3>
                  <p className="flex-1">{contract.contract_start_date || "N/A"}</p>
                </div>
                <div className="flex">
                  <h3 className="font-medium min-w-[200px]">Working Time Pattern:</h3>
                  <p className="flex-1">{contract.working_time_pattern || "N/A"}</p>
                </div>
                <div className="flex">
                  <h3 className="font-medium min-w-[200px]">Contracted Hours per <br /> Week:</h3>
                  <p className="flex-1">{contract.contracted_hours_per_week || "N/A"}</p>
                </div>
                <div className="flex">
                  <h3 className="font-medium min-w-[200px]">Annual Leave Year Start:</h3>
                  <p className="flex-1">{contract.annual_leave_year_start || "N/A"}</p>
                </div>
                <div className="flex">
                  <h3 className="font-medium min-w-[200px]">Min. Leave Entitlement:</h3>
                  <p className="flex-1">{contract.min_leave_entitlement || "N/A"}</p>
                </div>
                <h1 className="text-lg font-poppins font-semibold">Place of Work</h1>
                <div className="flex">
                  <h3 className="font-medium min-w-[200px]">Working Location:</h3>
                  <p className="flex-1">{contract.working_location || "N/A"}</p>
                </div>
                <div className="flex">
                  <h3 className="font-medium min-w-[200px]">Address:</h3>
                  <p className="flex-1">{contract.address || "N/A"}</p>
                </div>
                <div className="flex">
                  <h3 className="font-medium min-w-[200px]">Public Holidays:</h3>
                  <p className="flex-1">{contract.public_holidays || "N/A"}</p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="pl-5">No contracts found for this employee.</p>
        )}

        {user.role === "hr" && (
          <button
            className="mt-4 mb-4 px-4 ml-5 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
            onClick={() => setIsModalOpen(true)}
          >
            Add Contract
          </button>
        )}

        {/* Modal for adding/editing contracts */}
        <Modal
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            resetFormData();
          }}
          title={formData.id ? "Edit Contract" : "Add Contract"}
          onSubmit={handleSubmit}
          formData={formData}
          handleChange={handleChange}
        />

        {/* Delete Confirmation Modal */}
        {isDeleteModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="bg-white p-4 rounded shadow-md">
              <h3 className="text-lg font-bold">Confirm Deletion</h3>
              <p>Are you sure you want to delete this contract?</p>
              <div className="flex justify-end mt-4">
                <button className="mr-2 bg-gray-200 px-4 py-2 rounded" onClick={closeDeleteModal}>
                  Cancel
                </button>
                <button className="bg-red-500 text-white px-4 py-2 rounded" onClick={confirmDelete}>
                  Delete
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Contracts;
