import React, { useEffect, useState } from 'react';

const Dashboard = () => {
    const [companyCount, setCompanyCount] = useState(0);
    const [userCounts, setUserCounts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    // Fetch total companies and user counts on component mount
    useEffect(() => {
        const fetchCounts = async () => {
            try {
                const response = await fetch('https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/admin/get_users.php?action=count');
                const data = await response.json();
                if (data.error) {
                    throw new Error(data.error);
                }
                setCompanyCount(data.company_count);
                setUserCounts(data.user_counts);
            } catch (err) {
                setError('Error fetching data: ' + err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchCounts();
    }, []);

    return (
        <div className="flex flex-col h-screen">
            {/* Header */}
            <header className="bg-blue-600 text-white p-4 text-center text-2xl">
                Company Dashboard
            </header>

            {/* Main Content */}
            <div className="flex flex-1">
                {/* Sidebar */}
                <aside className="bg-gray-800 text-white w-64 p-5">
                    <nav>
                        <ul>
                            <li className="mb-3">
                                <a href="/admin" className="hover:bg-gray-700 p-2 block rounded">Dashboard</a>
                            </li>
                            <li className="mb-3">
                                <a href="/admin/company" className="hover:bg-gray-700 p-2 block rounded">Companies</a>
                            </li>
                            <li className="mb-3">
                                <a href="/admin" className="hover:bg-gray-700 p-2 block rounded">Users</a>
                            </li>
                            <li className="mb-3">
                                <a href="/admin" className="hover:bg-gray-700 p-2 block rounded">Reports</a>
                            </li>
                            <li className="mb-3">
                                <a href="/admin" className="hover:bg-gray-700 p-2 block rounded">Settings</a>
                            </li>
                        </ul>
                    </nav>
                </aside>

                {/* Content Area */}
                <main className="flex-1 p-6 bg-gray-100">
                    {loading ? (
                        <p>Loading data...</p>
                    ) : error ? (
                        <p className="text-red-500">{error}</p>
                    ) : (
                        <div>
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                                <div className="bg-white p-4 shadow rounded-lg">
                                    <h3 className="text-lg font-semibold">Total Companies</h3>
                                    <p className="text-2xl font-bold">{companyCount}</p>
                                </div>
                                <div className="bg-white p-4 shadow rounded-lg">
                                    <h3 className="text-lg font-semibold">Active Users</h3>
                                    <p className="text-2xl font-bold">{userCounts.reduce((total, user) => total + user.member_count, 0)}</p>
                                </div>
                                <div className="bg-white p-4 shadow rounded-lg">
                                    <h3 className="text-lg font-semibold">Pending Requests</h3>
                                    <p className="text-2xl font-bold">5</p>
                                </div>
                            </div>
                            <div className="mt-6">
                                <h2 className="text-xl font-bold mb-4">Recent Activities</h2>
                                <div className="bg-white p-4 shadow rounded-lg">
                                    <ul>
                                        <li className="border-b py-2">No recent activities found</li>
                                    
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )}
                </main>
            </div>

            {/* Footer */}
            <footer className="bg-blue-600 text-white p-4 text-center">
                © 2024 Company Name. All rights reserved.
            </footer>
        </div>
    );
};

export default Dashboard;
