import React, { useEffect } from 'react';

const Loading = () => {
  useEffect(() => {
    const cards = document.querySelectorAll('.card');
    cards.forEach((card) => {
      card.classList.add('loaded');
    });
  }, []);

  return (
    <>


<div class="w-full gap-x-2 flex mt-64 justify-center items-center">
  <div
    class="w-5 bg-[#d991c2] animate-pulse h-5 rounded-full animate-bounce"
  ></div>
  <div
    class="w-5 animate-pulse h-5 bg-[#9869b8] rounded-full animate-bounce"
  ></div>
  <div
    class="w-5 h-5 animate-pulse bg-[#6756cc] rounded-full animate-bounce"
  ></div>
</div>


      
    </>
  );
};

export default Loading;
