import React from 'react';
import first from '../../assets/img/first.svg';
import second from '../../assets/img/second.svg';
import third from '../../assets/img/third.svg';
import four from '../../assets/img/four.svg';
import five from '../../assets/img/five.svg';
import six from '../../assets/img/six.svg';
import seven from '../../assets/img/seven.svg';
import eight from '../../assets/img/eight.svg';

const Data = () => {
  const links = [
    { name: "Readiness Assessments", url: first },
    { name: "Assessment Automation", url: second },
    { name: "Data Mapping & Inventory", url: third },
    { name: "Website Scanning & Cookie Compliance", url: four },
    { name: "Subject Access Rights Portal", url: five },
    { name: "Consent Management", url: six },
    { name: "Vendor Risk Management", url: seven },
    { name: "Readiness Assessments", url: eight },
  ];

  return (
    <section className="p-6">
      {/* Responsive grid for 1, 2, or 3 columns based on screen size */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {/* Map through links array and render each item */}
        {links.map((link, index) => (
          <div
            key={index}
            className="bg-blue-300 w-full h-52 p-6 rounded-3xl flex flex-col items-center justify-center transition-transform duration-300 hover:scale-105"
          >
            <img src={link.url} alt={link.name} className="w-16 h-16 mb-4" />
            <h1 className="text-center text-sm mb-4">{link.name}</h1>
            <button className="bg-blue-100 h-11 w-32 rounded-2xl transition-colors duration-300 hover:bg-blue-200">
              Get Started
            </button>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Data;