import React, { useState } from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';

const ReportDashboard = () => {
  // Employee data with state
  const [employees, setEmployees] = useState([
    { id: 1, name: 'Luke Daniel Johnson', payRate: 40000, payMethod: 'Direct deposit', status: 'Active' },
    { id: 2, name: 'Ramya Borra', payRate: 35000, payMethod: 'Direct deposit', status: 'Active' },
    { id: 3, name: 'Rishabh Sinha', payRate: 20000, payMethod: 'Check', status: 'Active' },
    { id: 4, name: 'Marlon Dassanayake', payRate: 60000, payMethod: 'Check', status: 'Active' },
  ]);

  const [editingEmployee, setEditingEmployee] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [newEmployee, setNewEmployee] = useState({ name: '', payRate: 0, payMethod: 'Direct deposit', status: 'Active' });

  // Filter employees based on search
  const filteredEmployees = employees.filter((employee) =>
    employee.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Calculate total payroll dynamically
  const totalPayroll = filteredEmployees.reduce((sum, employee) => sum + employee.payRate, 0);

  // Prepare data for Pie Chart (without showing names)
  const payrollData = filteredEmployees.map(employee => ({
    value: employee.payRate,
  }));

  // Pie chart colors
  const COLORS = ['#0088FE', '#FFBB28', '#FF8042', '#00C49F'];

  // Handle form submission to add a new employee
  const handleAddEmployee = (e) => {
    e.preventDefault();
    setEmployees([...employees, { id: employees.length + 1, ...newEmployee }]);
    setNewEmployee({ name: '', payRate: 0, payMethod: 'Direct deposit', status: 'Active' }); // Reset form
  };

  return (
    <div className="p-6 bg-gray-100">
      {/* Summary Cards */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
        <div className="bg-white p-6 rounded-lg text-center shadow">
          <h3 className="text-green-600 text-3xl font-bold">£{totalPayroll.toLocaleString()}</h3>
          <p className="text-gray-500">Total Payroll Cost</p>
        </div>

        <div className="bg-white p-6 rounded-lg shadow flex items-center justify-center">
          {/* Recharts Pie Chart for Payroll Distribution */}
          <PieChart width={250} height={250}>
            <Pie
              data={payrollData}
              dataKey="value"
              cx="50%"
              cy="50%"
              outerRadius={80}
              fill="#8884d8"
              label
            >
              {payrollData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip formatter={(value) => `£${value.toLocaleString()}`} />
            <Legend
              payload={payrollData.map((entry, index) => ({
                id: index,
                type: 'circle',
                value: `£${entry.value.toLocaleString()}`,
                color: COLORS[index % COLORS.length],
              }))}
              layout="vertical"
              align="right"
              verticalAlign="middle"
            />
          </PieChart>
        </div>

        <div className="bg-white p-6 rounded-lg shadow">
          <p>New payroll due Friday, 8/31</p>
          <a href="/" className="text-green-500">Paycheck list</a>
          <p className="mt-2">
            Want to automate your payroll taxes?
            <a href="/" className="text-green-500"> Checkout full service payroll</a>
          </p>
        </div>
      </div>

      {/* Search Bar */}
      <div className="bg-white p-4 rounded-lg shadow mb-6 flex flex-col md:flex-row">
        <input
          type="text"
          placeholder='Search "Luke Daniel Johnson"'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="border p-2 rounded-lg w-full md:w-3/4"
        />
        <button className="bg-green-500 text-white p-2 rounded-lg md:ml-2 mt-2 md:mt-0">Search</button>
      </div>

      {/* Add Employee Form */}
      <div className="bg-white p-4 rounded-lg shadow mb-6">
        <form onSubmit={handleAddEmployee} className="flex flex-col space-y-4">
          <input
            type="text"
            placeholder="Employee Name"
            value={newEmployee.name}
            onChange={(e) => setNewEmployee({ ...newEmployee, name: e.target.value })}
            className="border p-2 rounded-lg w-full"
            required
          />
          <input
            type="number"
            placeholder="Pay Rate"
            value={newEmployee.payRate}
            onChange={(e) => setNewEmployee({ ...newEmployee, payRate: parseFloat(e.target.value) })}
            className="border p-2 rounded-lg w-full"
            required
          />
          <select
            value={newEmployee.payMethod}
            onChange={(e) => setNewEmployee({ ...newEmployee, payMethod: e.target.value })}
            className="border p-2 rounded-lg w-full"
          >
            <option value="Direct deposit">Direct deposit</option>
            <option value="Check">Check</option>
          </select>
          <button type="submit" className="bg-green-500 text-white p-2 rounded-lg">Add Employee</button>
        </form>
      </div>

      {/* Payroll Table */}
      <div className="bg-white rounded-lg shadow overflow-x-auto">
        <table className="min-w-full table-auto">
          <thead>
            <tr className="bg-green-100">
              <th className="px-4 py-2">Name</th>
              <th className="px-4 py-2">Pay Rate</th>
              <th className="px-4 py-2">Pay Method</th>
              <th className="px-4 py-2">Status</th>
              <th className="px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredEmployees.length > 0 ? (
              filteredEmployees.map((employee) => (
                <tr key={employee.id} className="border-t">
                  <td className="px-4 py-2">{employee.name}</td>
                  <td className="px-4 py-2">£{employee.payRate.toLocaleString()}/year</td>
                  <td className="px-4 py-2">{employee.payMethod}</td>
                  <td className={`px-4 py-2 ${employee.status === 'Active' ? 'text-green-500' : 'text-red-500'}`}>
                    {employee.status}
                  </td>
                  <td className="px-4 py-2">
                    <button
                      onClick={() => setEditingEmployee(employee.id)}
                      className="bg-blue-500 text-white p-2 rounded-lg"
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="px-4 py-2 text-center">
                  No employees found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ReportDashboard;
