import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MdFileDownload } from "react-icons/md";
import { MdDelete } from "react-icons/md"; 
const DocumentUpload = () => {
    const [file, setFile] = useState(null);
    const [documents, setDocuments] = useState([]);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const user = JSON.parse(localStorage.getItem('user')) || {};
    const companyName = user.company || '';

    useEffect(() => {
        if (companyName) {
            fetchDocuments();
        }
    }, [companyName]);

    const fetchDocuments = async () => {
        if (!companyName) return;

        try {
            const response = await axios.get(`https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/doc/read.php?company_name=${companyName}`);
            setDocuments(response.data);
        } catch (error) {
            console.error('Error fetching documents:', error);
            toast.error('Failed to load documents.');
        }
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage('');
        setLoading(true);

        const formData = new FormData();
        formData.append('company_name', companyName);
        formData.append('document', file);

        try {
            const response = await axios.post('https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/doc/add_doc.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.status === 'success') {
                toast.success('Document uploaded successfully!');
                fetchDocuments();
                setFile(null);
            } else {
                toast.error(`Error: ${response.data.message}`);
            }
        } catch (error) {
            toast.error('An error occurred while uploading the document.');
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (documentId) => {
        if (window.confirm('Are you sure you want to delete this document?')) {
            try {
                const response = await axios.delete(`https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/doc/delete.php?id=${documentId}`);
                if (response.data.status === 'success') {
                    toast.success('Document deleted successfully!');
                    fetchDocuments();
                } else {
                    toast.error(`Error: ${response.data.message}`);
                }
            } catch (error) {
                toast.error('An error occurred while deleting the document.');
                console.error(error);
            }
        }
    };

    const handleDownload = (documentId) => {
        window.open(`https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/doc/download.php?id=${documentId}`, '_blank');
    };

    return (
        <>
        <div className="document-upload p-6 rounded-lg ">
            {user.role==="hr" && (
                <><h2 className="text-[25px] font-semibold mb-4">Upload Document for {companyName}</h2><form onSubmit={handleSubmit} className="mb-6">
                        <div className="mb-4">
                            <label htmlFor="document" className="block text-sm font-medium text-gray-700 mb-2">Upload Document:</label>
                            <input
                                type="file"
                                id="document"
                                onChange={handleFileChange}
                                required
                                className="p-2 w-full" />
                        </div>
                        <button type="submit" disabled={loading} className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition">
                            {loading ? 'Uploading...' : 'Upload'}
                        </button>
                    </form></>
            )}
        

            {message && <p className="text-red-600">{message}</p>}

            <h2 className="text-xl font-semibold mt-6 mb-4">Uploaded Documents</h2>
            <ul className="bg-white shadow rounded-lg">
                {documents.length > 0 ? (
                    documents.map((doc) => (
                        <li key={doc.id} className="flex justify-between items-center border-b p-4 hover:bg-gray-50">
                            <div>
                                <span className="font-medium">{doc.file_name}</span>
                                <span className="text-gray-500 text-sm"> - {new Date(doc.created_at).toLocaleString()}</span>
                            </div>
                            <div className="flex space-x-4">
                                <button onClick={() => handleDownload(doc.id)} className="text-blue-600 hover:text-blue-700 transition">
                                <MdFileDownload  />
                                </button>
                            {user.role ==="hr" && (
                                    <button onClick={() => handleDelete(doc.id)} className="text-red-600 hover:text-red-700 transition">
                                    <MdDelete  />
                                    </button>
                            )}
                            </div>
                        </li>
                    ))
                ) : (
                    <li className="p-4 text-gray-500 text-center">No documents found.</li>
                )}
            </ul>
        </div>
        </>
    );
};

export default DocumentUpload;
