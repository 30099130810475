import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Modal from 'react-modal';
import { HiEye } from "react-icons/hi";

const EmployeeActivity = () => {
    const [employees, setEmployees] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [error, setError] = useState(null);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [dateActivities, setDateActivities] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [activityModalIsOpen, setActivityModalIsOpen] = useState(false);
    const [selectedDepartment, setSelectedDepartment] = useState('');

    Modal.setAppElement('#root');
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',
            width: '400px',
            padding: '20px',
            borderRadius: '8px',
        },
    };

    // Fetch employees based on the user's company and role
    useEffect(() => {
        const fetchEmployees = async () => {
            const user = JSON.parse(localStorage.getItem('user'));
            const company = user ? user.company : null;
            const role = user ? user.role : null;
            const userId = user ? user.id : null; // Get the user ID from local storage
    
            if (!company) {
                setError('Company not found in local storage.');
                return;
            }
    
            try {
                const endpoint = role === 'hr'
                    ? (selectedDepartment
                        ? `https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/time/fetch_employees_by_department.php?company=${company}&department=${selectedDepartment}&role=${role}`
                        : `https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/time/fetch_employees_by_department.php?company=${company}&role=${role}`)
                    : `https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/time/fetch_employees_by_department.php?company=${company}&user_id=${userId}&role=${role}`;
    
                const response = await axios.get(endpoint);
    
                if (response.data.status === 'success') {
                    setEmployees(response.data.employees);
                    const uniqueDepartments = [...new Set(response.data.employees.map(emp => emp.department))];
                    setDepartments(uniqueDepartments);
                } else {
                    setError(response.data.message);
                }
            } catch (err) {
                setError('Failed to fetch employees.');
            }
        };
    
        fetchEmployees();
    }, [selectedDepartment]);
    

    const fetchActivitiesForDate = async (employeeId, date) => {
        try {
            const response = await axios.post('https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/task/fetch_activities.php', {
                user_id: employeeId,
                date: date.toISOString().split('T')[0],
            });

            if (response.data.status === 'success') {
                setDateActivities(response.data.activities);
            } else {
                setDateActivities([]);
                setError(response.data.message);
            }
        } catch (err) {
            setError('Failed to fetch activities.');
        }
    };

    const handleEmployeeClick = (employee) => {
        setSelectedEmployee(employee);
        setModalIsOpen(true);
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
        if (selectedEmployee) {
            fetchActivitiesForDate(selectedEmployee.id, date);
            setActivityModalIsOpen(true);
        }
    };

    const handleDepartmentChange = (e) => {
        setSelectedDepartment(e.target.value);
    };

    const closeModal = () => setModalIsOpen(false);
    const closeActivityModal = () => setActivityModalIsOpen(false);

    return (
        <div className="p-7 font-poppins">
            <h1 className="text-xl font-semibold mb-5">Select Department and View Employee Activities</h1>
            {error && <p className="text-red-500">{error}</p>}

            <select onChange={handleDepartmentChange} value={selectedDepartment} className="mb-5 pr-7 cursor-pointer p-2 border rounded">
                <option value="">All Departments</option>
                {departments.map((dept, index) => (
                    <option key={index} value={dept}>
                        {dept}
                    </option>
                ))}
            </select>

            <section className="grid grid-cols-1 lg:grid-cols-2 gap-10">
                {employees.map((employee) => (
                    <div key={employee.id} onClick={() => handleEmployeeClick(employee)} className="cursor-pointer flex rounded-2xl bg-blue-100 p-5 h-40 w-96 shadow-lg items-center">
                        <div className="bg-gray-200 rounded-full h-16 w-16 flex justify-center text-xl items-center align-middle">
                            {employee.profile_pic === null ? (
                                <span className='rounded-full'>{employee.first_name.charAt(0)} {employee.last_name.charAt(0)}</span>
                            ) : (
                                <img src={`https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/${employee.profile_pic}`} alt="" className='rounded-full h-16 w-16' />
                            )}
                        </div>
                        <div className="ml-5">
                            <h1 className="text-lg font-semibold">{employee.first_name} {employee.last_name}</h1>
                            <div className="flex items-center mt-2 text-gray-700">
                                <HiEye color="red" className="mr-2" />
                                View Calendar
                            </div>
                        </div>
                    </div>
                ))}
            </section>

            {/* Modal for Calendar */}
            <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles}>
                <h2>Select a Date</h2>
                <Calendar onChange={handleDateChange} value={selectedDate} />
                <button onClick={closeModal} className="mt-4 bg-blue-500 text-white px-4 py-2 rounded">
                    Close
                </button>
            </Modal>

            {/* Modal for Activities */}
            <Modal isOpen={activityModalIsOpen} onRequestClose={closeActivityModal} style={customStyles}>
                <h2>Activities for {selectedDate.toLocaleDateString()}</h2>
                {dateActivities.length > 0 ? (
                    <ul>
                        {dateActivities.map((activity) => (
                            <li key={activity.id}>
                                {activity.task_name} - {activity.start_time}
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>No activities found for this date.</p>
                )}
                <button onClick={closeActivityModal} className="mt-4 bg-blue-500 text-white px-4 py-2 rounded">
                    Close
                </button>
            </Modal>
        </div>
    );
};

export default EmployeeActivity;
