import React, { useState } from 'react';
import logo from '../assets/logo.svg';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false); // State to manage the mobile menu toggle

  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };
const navigate = useNavigate()
const handleClick = () =>{
  navigate('/register')
}
  return (
    <section className="relative overflow-x-hidden h-screen">
      {/* Semi-Circle Background */}
      <div id="elipse" className="hidden 2xl:-top-cus md:block absolute inset-x-0 -top-96 mx-auto w-full h-screen bg-yellow-200 rounded-t-full rotate-180"></div>

      {/* Navbar */}
      <nav className="relative font-poppins text-blue-800 bg-white/30 backdrop-blur-md shadow-lg border border-white/40 z-10">
        <div className="flex items-center justify-between p-4 md:px-10 lg:px-20">
          {/* Logo */}
          <img src={logo} alt="Logo" className="w-24 h-16 md:w-32 md:h-20" />

          {/* Hamburger Icon (Visible on small screens) */}
          <button className="text-blue-800 md:hidden focus:outline-none" onClick={handleToggle}>
            <svg className="w-8 h-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              {isOpen ? (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              ) : (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
              )}
            </svg>
          </button>

          {/* Navbar Links for larger screens */}
          <div className="hidden md:flex space-x-10 lg:space-x-12">
            <a href="#about" className="hover:underline h-10 w-24 font-bold bg-black rounded-lg shadow-xl text-center pt-2">About</a>
            <a href="#features" className="hover:underline">Features</a>
            <a href="#pricing" className="hover:underline">Pricing</a>
            <a href="#faq" className="hover:underline">Faq</a>
            <a href="/login" className="hover:underline">Login</a>
          </div>
        </div>

        {/* Sliding Menu for small screens */}
        <div
          className={`fixed inset-y-0 right-0 w-64 bg-white p-6 shadow-xl transform ${isOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 ease-in-out md:hidden`}
        >
          <button className="text-blue-800 focus:outline-none mb-4" onClick={() => setIsOpen(false)}>
            <svg className="w-8 h-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>

          {/* Mobile Links */}
          <nav className="flex flex-col space-y-4 text-lg font-semibold">
            <a href="#about" className="hover:bg-yellow-100 py-3 px-4 rounded-lg shadow-md text-blue-800">About</a>
            <a href="#features" className="hover:bg-yellow-100 py-3 px-4 rounded-lg shadow-md text-blue-800">Features</a>
            <a href="#pricing" className="hover:bg-yellow-100 py-3 px-4 rounded-lg shadow-md text-blue-800">Pricing</a>
            <a href="#faq" className="hover:bg-yellow-100 py-3 px-4 rounded-lg shadow-md text-blue-800">Faq</a>
            <a href="#login" className="hover:bg-yellow-100 py-3 px-4 rounded-lg shadow-md text-blue-800">Login</a>
          </nav>
        </div>
      </nav>

      {/* Main Content */}
      <div data-aos="fade-up" data-aos-duration="1000" className="container mt-72 xl:mt-54 md:ml-40 lg:ml-80 text-center md:text-left">
        <h1 className="text-4xl md:text-5xl lg:text-6xl font-extrabold">
          <span className="text-yellow-200">HR System</span> & Compliance
        </h1>
        <p className="font-poppins text-base md:text-lg mt-7 leading-loose w-full md:w-[700px] mx-auto md:mx-0">
          Manage your employees in compliance with the ZarvisGenix standards. Join 100+ people using ZarvisGenix.
        </p>
        <button onClick={handleClick} className="rounded-2xl lg:ml-40 text-lg md:text-xl mt-10 w-48 md:w-60 h-12 md:h-14 bg-yellow-300 text-center font-semibold text-white shadow-xl">
          Register Now
        </button>
      </div>
    </section>
  );
};

export default Navbar;
