import React from 'react';

const Features = () => {
  return (
    <>
      <section className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 mt-8 justify-items-center px-4 md:px-0">
        <div className="font-poppins border shadow-xl font-bold text-center pt-12 pb-8 space-y-2 border-green-500 h-64 w-full sm:w-80 rounded-3xl transition-transform transform hover:scale-105">
          <h1 data-aos="fade-up" data-aos-duration="300" className="text-3xl font-extrabold">100+</h1>
          <p data-aos="fade-up" data-aos-duration="300" className="text-lg">Satisfied Customers</p>
        </div>
        <div className="font-poppins shadow-xl border font-bold text-center pt-12 pb-8 space-y-2 border-green-500 h-64 w-full sm:w-80 rounded-3xl transition-transform transform hover:scale-105">
          <h1 data-aos="fade-up" data-aos-duration="300" className="text-3xl font-extrabold">5+</h1>
          <p data-aos="fade-up" data-aos-duration="300" className="text-lg">Immigration Advisors</p>
        </div>
        <div className="font-poppins border shadow-xl font-bold text-center pt-12 pb-8 space-y-2 border-green-500 h-64 w-full sm:w-80 rounded-3xl transition-transform transform hover:scale-105">
          <h1 data-aos="fade-up" data-aos-duration="300" className="text-3xl font-extrabold">10+</h1>
          <p data-aos="fade-up" data-aos-duration="300" className="text-lg">Legal Checks</p>
        </div>
        <div className="font-poppins border shadow-xl font-bold text-center pt-12 pb-8 space-y-2 border-green-500 h-64 w-full sm:w-80 rounded-3xl transition-transform transform hover:scale-105">
          <h1 data-aos="fade-up" data-aos-duration="300" className="text-3xl font-extrabold">24/7</h1>
          <p data-aos="fade-up" data-aos-duration="300" className="text-lg">Access Anywhere</p>
        </div>
      </section>
    </>
  );
};

export default Features;
