import React from 'react'
import Sidebar from '../Sidebar'
import Navbar from '../Navbar'
import EmployeeActivity from '../../EmployeeActivity'


const Cal = () => {
  return (
	<>
	
	<section className="flex">
      <Sidebar />
      <div className="flex-1">
        <Navbar />
    <EmployeeActivity/>
      </div>
    </section>
	</>
  )
}

export default Cal