import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { IoMdMail } from 'react-icons/io';
import { NavLink, useParams } from 'react-router-dom';
import { link } from '../../constants/sidebar';
import Sidebar from '../Sidebar';

const Timelog = () => {
    const { id } = useParams(); // Correct usage of useParams
    const [summary, setSummary] = useState({
        totalTimeTracked: '0 hrs 0 min',
        idealMinutes: '0 min',
        paidLeaves: '0 min',
        productiveWebsite: '0 hrs 0 min',
        manualTime: '0 min',
        mobileTime: '0 min'
    });

    const [taskLogs, setTaskLogs] = useState([]);
    const [employee, setEmployee] = useState(null);
    
    // Fetch task logs from the backend
    const fetchTaskLogs = async () => {
        try {
            const response = await fetch('https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/task/get_logs.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_id: id }), // Make sure it matches backend expectation
            });

            const data = await response.json();

            if (data.status === 'success') {
                const logs = data.tasks; // Updated key based on backend response
                setTaskLogs(logs);

                // Calculate total time tracked and update summary
                const totalTimeTracked = calculateTotalTime(logs);
                setSummary((prevSummary) => ({
                    ...prevSummary,
                    totalTimeTracked
                }));
            } else {
                console.error('Failed to fetch time logs:', data.message);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    // Function to calculate total time from task logs
    const calculateTotalTime = (logs) => {
        let totalMinutes = 0;

        logs.forEach((task) => {
            const startTime = new Date(task.start_time);
            const stopTime = task.stop_time ? new Date(task.stop_time) : new Date();

            // Calculate duration in minutes
            const durationMinutes = (stopTime - startTime) / (1000 * 60);
            totalMinutes += durationMinutes;
        });

        const hours = Math.floor(totalMinutes / 60);
        const minutes = Math.round(totalMinutes % 60);

        return `${hours} hrs ${minutes} min`;
    };

    // Fetch employee details from backend
    const fetchEmployeeDetails = async () => {
        try {
            const response = await axios.get(`https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/read_users.php?id=${id}`);
            setEmployee(response.data);
        } catch (err) {
            console.error("Error fetching employee details:", err);
        }
    };

    useEffect(() => {
        fetchTaskLogs();
        fetchEmployeeDetails();
    }, [id]);

    const userId = id; 
    const links = link(userId);

    return (
        <section className='flex'>
            <div className="w-1/5">
            <Sidebar/></div>
           <div className="">
           {employee ? (
                <div className="flex flex-col md:flex-row ml-6 mt-10 bg-blue-100 h-auto p-5 rounded-3xl items-center justify-center">
                    <div className="text-7xl border h-36 w-36 p-1 rounded-full bg-white flex items-center justify-center">
                        {employee.profile_pic ? (
                            <img src={employee.profile_pic} alt="" className="rounded-full h-36 w-36" />
                        ) : (
                            <span className="rounded-full text-gray-600 font-bold text-4xl">
                                {employee.first_name.charAt(0)}{employee.last_name.charAt(0)}
                            </span>
                        )}
                    </div>
                    <div className="ml-0 md:ml-8 mt-4 flex md:mt-0 text-center md:text-left">
                        <div>
                            <h1 className="text-2xl font-bold">{employee.first_name} {employee.last_name}</h1>
                            <p className="text-gray-600 mb-1 flex justify-center md:justify-start gap-3">
                                <strong>Department:</strong> {employee.department}
                            </p>
                            <p className="text-gray-600 flex justify-center md:justify-start gap-3">
                                <strong>Role:</strong> {employee.role}
                            </p>
                        </div>
                        <div className="flex justify-end items-center pl-72">
                            <p className="text-gray-600 mb-1 flex justify-center md:justify-start gap-3">
                                <strong><IoMdMail color="green" /></strong> {employee.email}
                            </p>
                        </div>
                    </div>
                </div>
            ) : (
                <div>Employee not found</div>
            )}
            
            <ul className='flex gap-16 ml-6 text-lg p-4 bg-blue-300 mt-8 rounded-t-2xl h-12'>
                {links.map((linkItem, index) => (
                    <li key={index}>
                        <NavLink
                            to={linkItem.url}
                            className={({ isActive }) =>
                                isActive ? "text-blue-200 border-t-2 font-bold" : "text-gray-700"
                            }
                            end
                        >
                            {linkItem.name}
                        </NavLink>
                    </li>
                ))}
            </ul>

            <div className="p-6 bg-white rounded-lg shadow-md">
                {/* Timesheet Overview Section */}
                <div className="grid grid-cols-6 gap-4 mb-6">
                    {[
                        { label: 'Total time tracked', value: summary.totalTimeTracked },
                        { label: 'Ideal minutes', value: summary.idealMinutes },
                        { label: 'Paid leaves', value: summary.paidLeaves },
                        { label: 'Productive website', value: summary.productiveWebsite },
                        { label: 'Manual time', value: summary.manualTime },
                        { label: 'Mobile time', value: summary.mobileTime }
                    ].map((stat, index) => (
                        <div key={index} className="p-4 bg-blue-50 rounded-lg text-center">
                            <p className="text-gray-500 text-sm">{stat.label}</p>
                            <p className="text-green-600 text-xl font-semibold">{stat.value}</p>
                        </div>
                    ))}
                </div>

                {/* Timelines Table */}
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white">
                        <thead>
                            <tr className="bg-gray-100">
                                <th className="py-3 px-4 text-left">Task Name</th>
                                <th className="py-3 px-4 text-left">Date</th>
                                <th className="py-3 px-4 text-left">Time worked</th>
                                <th className="py-3 px-4 text-left">Start time</th>
                                <th className="py-3 px-4 text-left">End time</th>
                            </tr>
                        </thead>
                        <tbody className="text-gray-600 text-sm">
                            {taskLogs.map((task, index) => (
                                <tr key={index} className="border-t">
                                    <td className="py-3 px-4">{task.task_name}</td>
                                    <td className="py-3 px-4">{new Date(task.start_time).toLocaleDateString()}</td>
                                    <td className="py-3 px-4">
                                        {task.stop_time 
                                            ? `${Math.floor((new Date(task.stop_time) - new Date(task.start_time)) / (1000 * 60 * 60))} hrs 
                                            ${Math.floor((new Date(task.stop_time) - new Date(task.start_time)) / (1000 * 60) % 60)} min` 
                                            : 'Ongoing'}
                                    </td>
                                    <td className="py-3 px-4">{new Date(task.start_time).toLocaleTimeString()}</td>
                                    <td className="py-3 px-4">{task.stop_time ? new Date(task.stop_time).toLocaleTimeString() : 'Ongoing'}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
           </div>
        </section>
    );
};

export default Timelog;
