import React from "react";
import person from "../assets/person.svg";
import tick from "../assets/tick.svg";
import bag from "../assets/bag.svg";
import hand from "../assets/hand.svg";

const BudgetFeatures = () => {
  return (
    <section className="mt-14 font-poppins">
      <div className="text-center">
        <h1 data-aos="fade-up" data-aos-duration="1000" className="text-lg">
          Simple Licensing at
        </h1>
        <h1
          data-aos="fade-up"
          data-aos-duration="1000"
          className="text-3xl font-bold mt-4 underline underline-offset-1"
        >
          budget friendly pricing
        </h1>
        <button
          data-aos="fade-up"
          data-aos-duration="1000"
          className="mt-9 md:w-80 lg:w-96 text-gray-400 rounded-xl h-12 md:h-14 lg:h-16 bg-yellow-400 mx-auto lg:ml-[500px] block"
        >
          Start with a simple plan and scale up when you grow
        </button>
      </div>

      {/* Features Section */}
      <div
        data-aos="fade-up"
        data-aos-duration="1000"
        className="flex flex-wrap justify-center mt-10 space-y-16 md:space-y-0 md:space-x-9"
      >
        <div className="h-52 rounded-t-2xl pt-5 w-52 border mx-2 mb-8 md:mb-0">
          <img src={person} alt="/" className="pl-7" />
          <div className="bg-yellow-400 h-10 mt-6 pt-2 rounded-b-2xl">
            <h1 className="text-center">Access anywhere</h1>
          </div>
        </div>

        <div className="h-52 rounded-t-2xl pt-5 w-52 border mx-2 mb-8 md:mb-0">
          <img src={tick} alt="/" className="pl-7" />
          <div className="bg-yellow-400 h-10 mt-6 pt-2 rounded-b-2xl">
            <h1 className="text-center">Secure Service</h1>
          </div>
        </div>

        <div className="h-52 rounded-t-2xl pt-5 w-52 border mx-2 mb-8 md:mb-0">
          <img src={bag} alt="/" className="pl-7" />
          <div className="bg-yellow-400 h-10 mt-6 pt-2 rounded-b-2xl">
            <h1 className="text-center">Flexi Pricing</h1>
          </div>
        </div>

        <div className="h-52 rounded-t-2xl pt-5 w-52 border mx-2">
          <img src={hand} alt="/" className="pl-7" />
          <div className="bg-yellow-400 h-10 mt-6 pt-2 rounded-b-2xl">
            <h1 className="text-center">Instant Support</h1>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BudgetFeatures;
