import React, { useState, useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import axios from 'axios';
import { IoMdMail } from 'react-icons/io';
import { link } from '../constants/sidebar';
import Sidebar from './Sidebar';

const EmployeeDashboard = () => {
    const { id } = useParams();
    const [employee, setEmployee] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [leaveType, setLeaveType] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [leaves, setLeaves] = useState([]);
    const [isHrRole, setIsHrRole] = useState(false);
    const [showLeaveForm, setShowLeaveForm] = useState(false);

    const links = link(id);

    useEffect(() => {
        const fetchEmployee = async () => {
            try {
                const response = await axios.get(`https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/read_users.php?id=${id}`);
                setEmployee(response.data);
            } catch (err) {
                setError('Failed to fetch employee details. Please try again.');
            } finally {
                setLoading(false);
            }
        };

        fetchEmployee();
    }, [id]);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && (user.role === 'HR' || user.role === 'hr')) {
            setIsHrRole(true);
        }
    }, []);

    useEffect(() => {
        const fetchLeaves = async () => {
            try {
                const response = await axios.get(`https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/get_leaves.php?employee_id=${id}`);
                setLeaves(Array.isArray(response.data) ? response.data : []);
            } catch (err) {
                console.error(err);
                setError('Failed to fetch leave history. Please try again.');
            }
        };

        fetchLeaves();
    }, [id]);

    const applyLeave = async (e) => {
        e.preventDefault();
        try {
            await axios.post('https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/apply_leave.php', {
                employee_id: id,
                leave_type: leaveType,
                start_date: startDate,
                end_date: endDate,
            });
            const leavesResponse = await axios.get(`https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/get_leaves.php?employee_id=${id}`);
            setLeaves(Array.isArray(leavesResponse.data) ? leavesResponse.data : []);
            setLeaveType('');
            setStartDate('');
            setEndDate('');
            setShowLeaveForm(false);
        } catch (err) {
            console.error(err);
            setError('Failed to apply for leave. Please try again.');
        }
    };
    const approveLeave = async (leaveId) => {
        if (window.confirm("Are you sure you want to approve this leave request?")) {
            try {
                // Approve the leave request
                const approveResponse = await axios.post(
                    'https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/approve_leave.php',
                    { leave_id: leaveId }
                );
    
                // Log the response for troubleshooting
                console.log("Approve response:", approveResponse);
    
                // Check if the approval was successful
                if (approveResponse.data && approveResponse.data.status === 'success') {
                    // Fetch the updated list of leaves
                    const leavesResponse = await axios.get(
                        `https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/get_leaves.php?employee_id=${id}`
                    );
    
                    // Update the leaves state
                    setLeaves(Array.isArray(leavesResponse.data) ? leavesResponse.data : []);
    
                    // Display a success alert
                    alert('Leave approved successfully');
                } 
            } catch (err) {
                console.error("Error approving leave:", err);
                window.location.reload(); 
                
            }
        }
    };
    
    const deleteLeave = async (leaveId) => {
        if (window.confirm("Are you sure you want to delete this leave request?")) {
            try {
                await axios.delete(
                    `https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/delete_leave.php?leave_id=${leaveId}`
                );
    
                // Fetch the updated list of leaves
                const leavesResponse = await axios.get(
                    `https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/get_leaves.php?employee_id=${id}`
                );
    
                // Update the leaves state
                setLeaves(Array.isArray(leavesResponse.data) ? leavesResponse.data : []);
            } catch (err) {
                console.error("Error deleting leave:", err);
    
                // Check if there's a specific error response from the server
                window.location.reload(); 
            }
        }
    };
    

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    return (
        <section className="flex min-h-screen">
            <Sidebar className="w-1/5" />
            <div className="flex-1 p-6">
                {employee ? (
                    <div className="flex flex-col md:flex-row items-center bg-blue-100 p-5 rounded-3xl">
                        <div className="text-7xl border h-36 w-36 p-1 rounded-full bg-white flex items-center justify-center">
                            {employee.profile_pic ? (
                                <img src={employee.profile_pic} alt="" className="rounded-full h-36 w-36" />
                            ) : (
                                <span className="text-gray-600 font-bold text-4xl">
                                    {employee.first_name.charAt(0)}{employee.last_name.charAt(0)}
                                </span>
                            )}
                        </div>
                        <div className="ml-8 mt-4 text-center md:text-left">
                            <h1 className="text-2xl font-bold">{employee.first_name} {employee.last_name}</h1>
                            <p className="text-gray-600 mb-1"><strong>Department:</strong> {employee.department}</p>
                            <p className="text-gray-600"><strong>Role:</strong> {employee.role}</p>
                        </div>
                        <div className="flex-1 text-right">
                            <p className="text-gray-600 flex items-center justify-end gap-2">
                                <IoMdMail color="green" /> {employee.email}
                            </p>
                        </div>
                    </div>
                ) : (
                    <div>Employee not found</div>
                )}

                <ul className="flex gap-8 text-lg p-4 bg-blue-300 mt-8 rounded-t-2xl">
                    {links.map((linkItem, index) => (
                        <li key={index}>
                            <NavLink
                                to={linkItem.url}
                                className={({ isActive }) => isActive ? "text-blue-200 font-bold" : "text-gray-700"}
                                end
                            >
                                {linkItem.name}
                            </NavLink>
                        </li>
                    ))}
                </ul>

                <div className="bg-blue-400 p-6 rounded-b-xl mt-2">
                    <div className="flex  justify-center items-center mb-4">
                        <div className="bg-blue-300 p-5 rounded-xl">
                            <p>Annual leaves to take</p>
                            <p className="text-center text-blue-500">{leaves.length}/28 days</p>
                            <button onClick={() => setShowLeaveForm(true)} className="bg-blue-200 px-4 py-2 rounded-xl mt-2">Add leave</button>
                        </div>
                    </div>

                    {showLeaveForm && (
                        <form onSubmit={applyLeave} className="bg-gray-50 p-4 rounded-md shadow-md mt-6">
                            <input type="text" placeholder="Leave Type" value={leaveType} onChange={(e) => setLeaveType(e.target.value)} required className="w-full px-3 py-2 mb-4 border rounded-lg" />
                            <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} required className="w-full px-3 py-2 mb-4 border rounded-lg" />
                            <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} required className="w-full px-3 py-2 mb-4 border rounded-lg" />
                            <button type="submit" className="w-full bg-indigo-600 text-white py-2 rounded-lg hover:bg-indigo-700 transition">Apply Leave</button>
                        </form>
                    )}

                    <div className="mt-6">
                        {leaves.map((leave) => (
                            <div key={leave.id} className="p-4 bg-white shadow-md rounded-md mb-4">
                                <p><strong>Leave Type:</strong> {leave.leave_type}</p>
                                <p><strong>Start Date:</strong> {leave.start_date}</p>
                                <p><strong>End Date:</strong> {leave.end_date}</p>
                                <p><strong>Status:</strong> {leave.status}</p>
                                {isHrRole && (
                                    <div className="flex gap-2 mt-4">
                                        <button
                                            onClick={() => approveLeave(leave.id)}
                                            className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                                        >
                                            Approve
                                        </button>
                                        <button
                                            onClick={() => deleteLeave(leave.id)}
                                            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                                        >
                                            Delete
                                        </button>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default EmployeeDashboard;
