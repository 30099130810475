import React from "react";
import icons from "../assets/icons.svg";
import girl from "../assets/girl.svg";
import { useNavigate } from "react-router-dom";

const Offer = () => {
  const navigate = useNavigate()
const handleClick = () =>{
  navigate('/register')
}
  return (
    <section className="mt-16 md:mt-32 font-poppins flex flex-col md:flex-row justify-between items-center px-4 md:px-0">
      <div className="md:ml-32 text-center md:text-left">
        <p data-aos="fade-up"  data-aos-duration="1000" className="text-lg md:text-xl text-yellow-500">Register now and</p>
        <h1 data-aos="fade-up"  data-aos-duration="1000" className="text-3xl md:text-4xl font-bold mb-4">Join our community</h1>
        <div data-aos="fade-up"  data-aos-duration="1000" className="border-b-4 border-yellow-500 w-32 md:w-48 mx-auto md:mx-0 mb-6"></div>
        <button className="text-gray-400 py-2 bg-yellow-400 px-4 rounded-lg mr-4">
          Subscribe Today and Get Started!
        </button>
        <button onClick={handleClick} className="bg-blue-500 text-white py-3 px-6 rounded-3xl font-bold mt-4 block">
          Register Now
        </button>
        <img src={icons} alt="Icons" className="mt-8 mx-auto md:mx-0" />
      </div>
      <div data-aos="fade-up"  data-aos-duration="1000" className="md:mr-32 mt-8 md:mt-0">
        <img className="mx-auto md:ml-4" src={girl} alt="Girl illustration" />
      </div>
    </section>
  );
};

export default Offer;
