import React, { useState, useEffect } from "react";
import axios from "axios";
import {  NavLink, useParams } from "react-router-dom";
 // Modal component for adding/editing contracts

import PerModal from "./PerModal";
import { IoMdMail } from "react-icons/io";
import { link } from "../../constants/sidebar";
import Sidebar from "../Sidebar";


const Personal = () => {
  
  const [contracts, setContracts] = useState([]);
  
  const { id } = useParams(); // Get userId from URL
  const { id: employeeId } = useParams();
 
  const links = link(id); 

  const [formData, setFormData] = useState({
    id: null,
    employee_id: employeeId,
  first_name:"",
  middle_name:"",
  last_name:"",
  dob:"",
  nationality:"",
  personal_email:"",
  work_email:"",
  personal_phone:"",
  home_phone:""
 

   
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [contractToDelete, setContractToDelete] = useState(null);

  // Fetch employee details

  // Fetch contracts from the backend

  // Handle form input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle form submission (add/edit job role)
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (formData.id) {
        // Update existing job role
        const response = await axios.post("https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/emp/update.php", formData);
        alert(response.data.message);
        setContracts(
          contracts.map((contract) =>
            contract.id === formData.id ? { ...contract, ...formData } : contract
          )
        );
      } else {
        // Add new job role
        const response = await axios.post("https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/emp/create.php", formData);
        alert(response.data.message);
        setContracts([...contracts, { ...formData, id: response.data.id }]);
      }
      setIsModalOpen(false);
      resetFormData();
    } catch (error) {
      console.error("Error saving job role:", error);
    }
  };

  // Handle contract deletion confirmation modal
  const openDeleteModal = (contractId) => {
    setContractToDelete(contractId);
    setIsDeleteModalOpen(true);
  };

  const confirmDelete = async () => {
    try {
      const response = await axios.post("https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/emp/delete.php", { contract_id: contractToDelete });
      alert(response.data.message);
      setContracts(contracts.filter((contract) => contract.id !== contractToDelete));
      setIsDeleteModalOpen(false);
      setContractToDelete(null);
    } catch (error) {
      console.error("Error deleting job role:", error);
    }
  };

  // Close the delete modal
  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setContractToDelete(null);
  };
 const user = JSON.parse(localStorage.getItem('user'))
  // Handle edit action (pre-fill form data)
  const handleEdit = (contract) => {
    setFormData(contract);
    setIsModalOpen(true);
  };

  const resetFormData = () => {
    setFormData({
      id: null,
      employee_id: employeeId,
	  first_name:"",
	  middle_name:"",
	  last_name:"",
	  dob:"",
	  nationality:"",
	  personal_email:"",
	  work_email:"",
	  personal_phone:"",
	  home_phone:""
    });
  };
 
	
  const [employee, setEmployee] = useState(null);

  useEffect(() => {
	const fetchEmployee = async () => {
	  try {
		const response = await axios.get(`https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/read_users.php?id=${employeeId}`);
		setEmployee(response.data);
	  } catch (err) {
		console.error("Error fetching employee details:", err);
	  }
	};

	fetchEmployee();
  }, [employeeId]);

  useEffect(() => {
    const fetchContracts = async () => {
      try {
        const response = await axios.get(`https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/emp/read.php?employee_id=${employeeId}`);
        setContracts(response.data);
      } catch (error) {
        console.error("Error fetching contracts:", error);
      }
    };

    fetchContracts();
  }, [employeeId]);
  return (
    <section className="flex">
      <div className="w-1/5">
      <Sidebar/>
      </div>
      
      
      <div className="">
	  {employee ? (
  <div className="flex flex-col md:flex-row ml-6 mt-10 bg-blue-100 h-auto p-5 rounded-3xl items-center w justify-center">
    <div className="text-7xl border h-36 w-36 p-1 rounded-full bg-white flex items-center justify-center">
      {employee.profile_pic ? (
        <img src={employee.profile_pic} alt="" className="rounded-full h-36 w-36" />
      ) : (
        <span className="rounded-full text-gray-600 font-bold text-4xl">
          {employee.first_name.charAt(0)}{employee.last_name.charAt(0)}
        </span>
      )}
    </div>
    <div className="ml-0 md:ml-8 mt-4 flex md:mt-0 text-center md:text-left">
      <div className="">
	  <h1 className="text-2xl font-bold">{employee.first_name} {employee.last_name}</h1>
      
      <p className="text-gray-600 mb-1 flex justify-center md:justify-start gap-3">
        <strong>Department:</strong> {employee.department}
      </p>
      <p className="text-gray-600 flex justify-center md:justify-start gap-3">
        <strong>Role:</strong> {employee.role}
      </p>
	  </div>
	  <div className="flex justify-end items-center pl-72">
	  <p className="text-gray-600 mb-1 flex justify-center md:justify-start gap-3">
        <strong>                <IoMdMail color="green" /> </strong> {employee.email}
      </p>
   
	  </div>
    </div>
  </div>
) : (
  <div>Employee not found</div>
)}
<ul className='flex gap-16 ml-6 text-lg p-4 bg-blue-300 mt-8 rounded-t-2xl h-12'>
      {links.map((linkItem, index) => (
        <li key={index}>
          <NavLink
            to={linkItem.url}
            className={({ isActive }) =>
              isActive ? "text-blue-200 border-t-2 font-bold" : "text-gray-700"
            }
            end
          >
            {linkItem.name}
          </NavLink>
        </li>
      ))}
    </ul>

        <div className="p-4 bg-gray-50 rounded-b-md">
          {contracts.length > 0 ? (
            contracts.map((contract) => (
              <div
                key={contract.id}
                className="mb-4 p-4 bg-gray-100 w-full rounded-lg shadow-sm"
              >
                <div className="flex justify-end items-center mb-2">
                  <div className="flex space-x-2">
                    <button
                      onClick={() => handleEdit(contract)}
                      className="text-yellow-500 hover:underline"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => openDeleteModal(contract.id)}
                      className="text-red-500 hover:underline"
                    >
                      Delete
                    </button>
                  </div>
                </div>
                <div className="grid gap-4">
                  <div className="flex">
                    <h3 className="font-medium min-w-[200px]">First name:</h3>
                    <p className="flex-1">{contract.first_name || "N/A"}</p>
                  </div>
                  <div className="flex">
                    <h3 className="font-medium min-w-[200px]">Middle name:</h3>
                    <p className="flex-1">{contract.middle_name || "N/A"}</p>
                  </div>
				  <div className="flex">
                    <h3 className="font-medium min-w-[200px]">Last name:</h3>
                    <p className="flex-1">{contract.last_name || "N/A"}</p>
                  </div>
				  <div className="flex">
                    <h3 className="font-medium min-w-[200px]">Date Of Birth</h3>
                    <p className="flex-1">{contract.dob || "N/A"}</p>
                  </div>
				  <div className="flex">
                    <h3 className="font-medium min-w-[200px]">Nationality</h3>
                    <p className="flex-1">{contract.nationality || "N/A"}</p>
                  </div>
				  <div className="flex">
                    <h3 className="font-medium min-w-[200px]">Personal Email:</h3>
                    <p className="flex-1">{contract.personal_email || "N/A"}</p>
                  </div>
				  <div className="flex">
                    <h3 className="font-medium min-w-[200px]">Work Email</h3>
                    <p className="flex-1">{contract.work_email || "N/A"}</p>
                  </div>  <div className="flex">
                    <h3 className="font-medium min-w-[200px]">Personal Phone</h3>
                    <p className="flex-1">{contract.personal_phone || "N/A"}</p>
                  </div>
				  <div className="flex">
                    <h3 className="font-medium min-w-[200px]">Home Phone</h3>
                    <p className="flex-1">{contract.home_phone || "N/A"}</p>
                  </div>
             
                 
                </div>
              </div>
            ))
          ) : (
            <p className="pl-6">No personal information is available for this employee</p>
          )}
 
        {user.role === "hr" &&( 
            <button
            onClick={() => {
              resetFormData();
              setIsModalOpen(true);
            }}
            className="bg-green-500 ml-6 mt-4 text-white p-2 rounded hover:bg-green-600"
          >
            Add Personal Information
          </button>
)}

          {isModalOpen && (
            <PerModal
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              onSubmit={handleSubmit}
              formData={formData}
              handleChange={handleChange}
            />
          )}
        </div>
 

      {isDeleteModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75">
          <div className="group select-none w-96 flex flex-col p-4 relative items-center justify-center bg-gray-800 border border-gray-800 shadow-lg rounded-2xl">
            <div className="">
              <div className="text-center p-3 flex-auto justify-center">
                <h2 className="text-xl font-bold py-4 text-gray-200">Are you sure?</h2>
                <p className="font-bold text-sm text-gray-500 px-2">
                  Do you really want to continue? This process cannot be undone.
                </p>
              </div>
              <div className="p-2 mt-2 text-center space-x-4 md:block">
                <button
                  onClick={closeDeleteModal}
                  className="mb-2 md:mb-0 bg-gray-700 px-5 py-2 text-sm shadow-sm font-medium tracking-wider border-2 border-gray-600 text-gray-300 rounded-full hover:bg-gray-800"
                >
                  Cancel
                </button>
                <button
                  onClick={confirmDelete}
                  className="bg-red-500 hover:bg-transparent px-5 ml-2 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:text-red-500 border-2 border-red-500"
                >
                  Confirm Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
    </section>
  );
};

export default Personal;
