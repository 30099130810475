import React from 'react'
import Sidebar from '../Sidebar'
import Navbar from '../Navbar'
import Em from '../Em'

const Pa = () => {
  return (
	<>
	
	<section className="flex ">
      <Sidebar />
      <div className="flex-1">
        <Navbar />
        <Em/>
      </div>
    </section>
	</>
  )
}

export default Pa