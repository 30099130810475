import React, { useState } from 'react';
import axios from 'axios';

function Register() {
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        phone1: '',
        phone2: '',
        company: '',
        gender: '',
        department: '',
        role: 'hr', // Set the role as 'hr' by default
        plan: ''
    });
    const [profilePic, setProfilePic] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleFileChange = (e) => {
        setProfilePic(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = new FormData();
        Object.keys(formData).forEach((key) => data.append(key, formData[key]));
        if (profilePic) data.append('profile_pic', profilePic);

        try {
            const response = await axios.post('https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/register.php', data, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });

            if (response.data.status === 'success') {
                console.log(response.data.message || 'Registration successful!');
                window.location.href = response.data.url;
                localStorage.setItem('user', JSON.stringify(response.data.user)); // Save user data to localStorage
            }
            else {
                console.log(response.data.message || 'Registration failed. Please try again.');
            }
        } catch (error) {
            console.log('Registration failed. Please try again.');
            console.error("Error during registration:", error);
        }
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-cover bg-center" style={{ backgroundImage: 'url(https://your-image-link.com)' }}>
            <div className="w-full max-w-md p-8 space-y-6 bg-white bg-opacity-80 rounded-lg shadow-lg">
                <h2 className="text-2xl font-semibold text-center text-gray-800">Register</h2>
                
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div className="flex space-x-4">
                        <input
                            type="text"
                            name="first_name"
                            placeholder="First Name"
                            onChange={handleChange}
                            required
                            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                        />
                        <input
                            type="text"
                            name="last_name"
                            placeholder="Last Name"
                            onChange={handleChange}
                            required
                            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                        />
                    </div>
                    <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        onChange={handleChange}
                        required
                        className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                    />
                    <input
                        type="password"
                        name="password"
                        placeholder="Password"
                        onChange={handleChange}
                        required
                        className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                    />
                    <div className="flex space-x-4">
                        <input
                            type="tel"
                            name="phone1"
                            placeholder="Phone 1"
                            onChange={handleChange}
                            required
                            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                        />
                        <input
                            type="tel"
                            name="phone2"
                            placeholder="Phone 2"
                            onChange={handleChange}
                            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                        />
                    </div>
                    <input
                        type="text"
                        name="company"
                        placeholder="Company"
                        onChange={handleChange}
                        required
                        className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                    />
                    <select
                        name="gender"
                        onChange={handleChange}
                        required
                        className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                    >
                        <option value="">Select Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                    </select>
                    <input
                        type="text"
                        name="department"
                        placeholder="Department"
                        onChange={handleChange}
                        required
                        className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                    />
                    <input
                        type="text"
                        name="role"
                        placeholder="Role"
                        value="hr" // This field is hidden and fixed to 'hr'
                      hidden
                        className="w-full px-3 py-2 border rounded-lg bg-gray-200 cursor-not-allowed"
                    />
                    <select
                        name="plan"
                        onChange={handleChange}
                        required
                        className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                    >
                        <option value="">Select Plan</option>
                        <option value="basic_monthly">Basic Monthly</option>
                        <option value="basic_yearly">Basic Yearly</option>
                        <option value="standard_monthly">Standard Monthly</option>
                        <option value="standard_yearly">Standard Yearly</option>
                        <option value="enterprise_monthly">Enterprise Monthly</option>
                        <option value="enterprise_yearly">Enterprise Yearly</option>
                    </select>
                    <input
                        type="file"
                        name="profile_pic"
                        onChange={handleFileChange}
                        className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                    />
                    <button
                        type="submit"
                        className="w-full px-4 py-2 font-semibold text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring focus:ring-blue-300"
                    >
                        Register
                    </button>
                </form>
            </div>
        </div>
    );
}

export default Register;
