import React from 'react';

const HrProfile = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const planDetails = {
        basic_monthly: "Basic Monthly plan.",
        basic_yearly: "Basic Yearly plan.",
        standard_monthly: "Standard Monthly plan.",
        standard_yearly: "Standard Yearly plan.",
        enterprise_monthly: "Enterprise Monthly plan.",
        enterprise_yearly: "Enterprise Yearly plan.",
    };

    const expirationDate = user.subscription_expiry;
    const today = new Date();
    const expiryDate = new Date(expirationDate);
    const daysLeft = expirationDate ? Math.ceil((expiryDate - today) / (1000 * 60 * 60 * 24)) : null;

    return (
        <section className="flex items-center justify-center min-h-screen bg-gradient-to-br from-teal-600 to-blue-400 p-4 font-poppins">
            <div className="relative w-full max-w-sm bg-white shadow-xl rounded-2xl p-8">
                <div className="text-center mb-6">
                    <img
                        className="w-24 h-24 rounded-full border-4 border-teal-500 mx-auto shadow-lg"
                        src={`${user.profile_pic}`}
                        alt={`${user.first_name} ${user.last_name}`}
                    />
                    <h2 className="text-2xl font-bold text-gray-800 mt-4">
                        {user.first_name} {user.last_name}
                    </h2>
                    <p className="text-sm text-gray-500 font-medium">{user.role} at {user.company}</p>
                </div>
                <div className="bg-gradient-to-r from-teal-500 to-blue-400 h-1 rounded-full mb-6"></div>
                <div className="text-gray-700 space-y-2 text-sm">
                    <div className="flex justify-between">
                        <span className="font-semibold text-teal-600">Email:</span>
                        <span>{user.email}</span>
                    </div>
                    <div className="flex justify-between">
                        <span className="font-semibold text-teal-600">Phone 1:</span>
                        <span>{user.phone1}</span>
                    </div>
                    <div className="flex justify-between">
                        <span className="font-semibold text-teal-600">Phone 2:</span>
                        <span>{user.phone2}</span>
                    </div>
                    <div className="flex justify-between">
                        <span className="font-semibold text-teal-600">Department:</span>
                        <span>{user.department}</span>
                    </div>
                    <div className="flex justify-between">
                        <span className="font-semibold text-teal-600">Plan:</span>
                        <span>{user.plan ? planDetails[user.plan] : 'N/A'}</span>
                    </div>
                    <div className="flex justify-between">
                        <span className="font-semibold text-teal-600">Plan Expiration:</span>
                        <span>{expirationDate ? expiryDate.toDateString() : 'Not available'}</span>
                    </div>
                    <div className="flex justify-between">
                        <span className="font-semibold text-teal-600">Days Left:</span>
                        <span>{daysLeft !== null ? (daysLeft > 0 ? `${daysLeft} days left` : "Expired") : "Not available"}</span>
                    </div>
                </div>
                <div className="mt-8">
                    <button className="w-full py-2 bg-teal-600 text-white rounded-full font-semibold hover:bg-teal-700 transition-colors shadow-lg">
                        Manage Subscription
                    </button>
                </div>
            </div>
        </section>
    );
};

export default HrProfile;
