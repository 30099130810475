import React, { useState } from 'react';
import { IoMdArrowDropdown } from 'react-icons/io'; // Import dropdown icon
import { links } from '../constants/sidebar';
import { Link, useNavigate } from 'react-router-dom'; // Import Link from react-router-dom

const Sidebar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const currentPath = window.location.pathname;
    const user = JSON.parse(localStorage.getItem('user'));
    const navigate = useNavigate();

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const handleClick = () => {
        navigate('/hr');
    };

    // Filter links based on user role
    const filteredLinks = user.role === 'hr' 
        ? links 
        : links.filter(link => link.name !== 'Payroll' && link.name !== 'Expenses');

    return (
        <>
            {/* Button to toggle the sidebar (only on small screens) */}
            <button
                onClick={toggleSidebar}
                className="fixed top-4 w-full left-4 z-50 text-white px-4 py-2 rounded-md md:hidden flex items-center"
            >
                <span>{isOpen ? 'Close Menu' : 'Open Menu'}</span>
                <IoMdArrowDropdown
                    className={`ml-2 transform transition-transform duration-300 ${isOpen ? 'rotate-180' : 'rotate-0'}`}
                />
            </button>

            {/* Sidebar */}
            <div
                className={`fixed top-0 left-0 h-screen w-[250px] bg-white shadow-xl transition-transform transform md:translate-x-0 ${isOpen ? 'translate-x-0' : '-translate-x-full'} md:relative md:translate-x-0 md:block`}
            >
                <div className="flex items-center ml-3 p-4 bg-white rounded-lg shadow-md space-x-4">
                    <img
                        className="h-16 w-16 rounded-full object-cover border-2 border-gray-200"
                        src={`${user.profile_pic}`}
                        alt={`${user.first_name} ${user.last_name}`}
                    />
                    <div onClick={handleClick} className='cursor-pointer'>
                        <h1 className="text-lg font-semibold text-gray-800">{user.first_name} {user.last_name}</h1>
                        <p className="text-sm text-gray-600">{user.company}</p>
                        <p className="text-sm text-gray-500">{user.role}</p>
                    </div>
                </div>

                <ul className='mt-3 pl-7 text-xl space-y-5 font-poppins font-semibold'>
                    {filteredLinks.map((link, index) => (
                        <li
                            key={index}
                            style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}
                            className={`p-2 rounded-lg ${currentPath === link.url ? 'bg-gray-100' : ''}`}
                        >
                            <img
                                src={link.icon}
                                alt={link.name}
                                style={{ width: '24px', height: '24px', marginRight: '10px' }}
                            />
                            <Link to={link.url}>{link.name}</Link> {/* Use Link instead of a */}
                        </li>
                    ))}
                </ul>
            </div>
        </>
    );
};

export default Sidebar;




































