import React from 'react'
import Sidebar from '../Sidebar'
import Navbar from '../Navbar'

import Reports from './Reports'

const Report = () => {
  return (
	<>
	
	<section className="flex">
      <Sidebar />
      <div className="flex-1">
        <Navbar />
        <Reports/>
      </div>
    </section>
	</>
  )
}

export default Report 