import React, { useEffect, useState } from 'react';
import Contracts from '../../ContractForm';
import Job from './Job';
import { link } from '../../../constants/sidebar';
import { NavLink, useParams } from 'react-router-dom';
import axios from 'axios';
import Salary from './Salary';
import EmpPayroll from './EmpPayroll';
import Bank from './Bank';
import Notes from './Notes';
import Ter from './Ter';
import Info from './Info';
import { IoMdMail } from 'react-icons/io';
import Sidebar from '../../Sidebar';

const Employement = () => {
  const { id: employeeId } = useParams();
  const links = link(employeeId);
  const [employee, setEmployee] = useState(null);

  useEffect(() => {
    const fetchEmployee = async () => {
      try {
        const response = await axios.get(`https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/read_users.php?id=${employeeId}`);
        setEmployee(response.data);
      } catch (err) {
        console.error("Error fetching employee details:", err);
      }
    };

    fetchEmployee();
  }, [employeeId]);

  return (
    <section className="flex min-h-screen">
      {/* Sidebar Section */}
      <div className="w-1/5 ">
        <Sidebar />
      </div>
      
      {/* Main Content Section */}
      <div className="flex-1 p-6 font-poppins bg-gray-100">
        {employee ? (
          <div className="flex flex-col md:flex-row items-center bg-blue-100 h-auto p-5 rounded-3xl">
            <div className="text-7xl border h-36 w-36 p-1 rounded-full bg-white flex items-center justify-center">
              {employee.profile_pic ? (
                <img src={employee.profile_pic} alt="" className="rounded-full h-36 w-36" />
              ) : (
                <span className="text-gray-600 font-bold text-4xl">
                  {employee.first_name.charAt(0)}{employee.last_name.charAt(0)}
                </span>
              )}
            </div>
            <div className="ml-0 md:ml-8 mt-4 flex flex-col md:mt-0 text-center md:text-left">
              <h1 className="text-2xl font-bold">{employee.first_name} {employee.last_name}</h1>
              <p className="text-gray-600 mb-1"><strong>Department:</strong> {employee.department}</p>
              <p className="text-gray-600"><strong>Role:</strong> {employee.role}</p>
              <p className="text-gray-600 flex items-center justify-center md:justify-start gap-3 mt-2">
                <IoMdMail color="green" /> {employee.email}
              </p>
            </div>
          </div>
        ) : (
          <div>Employee not found</div>
        )}

        {/* Navigation Links */}
        <ul className="flex gap-8 text-lg p-4 bg-blue-300 mt-8 rounded-t-2xl">
          {links.map((linkItem, index) => (
            <li key={index}>
              <NavLink
                to={linkItem.url}
                className={({ isActive }) => isActive ? "text-blue-200 font-bold" : "text-gray-700"}
                end
              >
                {linkItem.name}
              </NavLink>
            </li>
          ))}
        </ul>

        {/* Main Components Section */}
        <div className="flex flex-col md:flex-row gap-8 mt-6">
          <div className="flex-1 bg-yellow-400 rounded shadow-md p-4">
            <Contracts />
            <Info />
          </div>
          <div className="flex-1 bg-blue-100 rounded shadow-md p-4 space-y-6">
            <Job />
            <Salary />
            <EmpPayroll />
            <Bank />
            <Notes />
            <Ter />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Employement;
