import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IoMdMail } from 'react-icons/io';
import { link } from '../constants/sidebar';
import Sidebar from './Sidebar';

const Docu = () => {
  // Rename `id` from URL params to `employeeId`
  const { id: employeeId } = useParams();
  const [documents, setDocuments] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const links = link(employeeId);

  // Fetch employee documents
  const fetchDocuments = async () => {
    try {
      const response = await axios.get(`https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/documents/fetch_documents.php?employee_id=${employeeId}`);
      if (response.data) {
        setDocuments(response.data);
      }
    } catch (error) {
      console.error('Error fetching documents:', error);
      toast.error('Failed to fetch documents.');
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, [employeeId]);

  // Handle document upload
  const handleAddDocument = async () => {
    if (!selectedFile) {
      toast.error('Please select a file to upload.');
      return;
    }

    const formData = new FormData();
    formData.append('document', selectedFile);
    formData.append('employee_id', employeeId); // Send as `employee_id` to backend

    try {
      const response = await axios.post('https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/documents/upload_document.php', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      if (response.data.status === 'success') {
        toast.success('Document uploaded successfully!');
        fetchDocuments(); // Refresh documents after upload
        setSelectedFile(null);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error('Error uploading document:', error);
      toast.error('Failed to upload the document.');
    }
  };

  // Handle document deletion
  const handleDelete = async (documentId) => {
    try {
      const response = await axios.delete(`https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/documents/delete_document.php?document_id=${documentId}`);
      if (response.data.status === 'success') {
        toast.success('Document deleted successfully!');
        fetchDocuments();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error('Error deleting document:', error);
      toast.error('Failed to delete the document.');
    }
  };

  // Employee details fetching
  const [employee, setEmployee] = useState(null);
  useEffect(() => {
    const fetchEmployee = async () => {
      try {
        const response = await axios.get(`https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/read_users.php?id=${employeeId}`);
        setEmployee(response.data);
      } catch (err) {
        console.error('Error fetching employee details:', err);
      }
    };

    fetchEmployee();
  }, [employeeId]);

  return (
    <section className='flex'>
      <div className="w-1/5">
      <Sidebar/></div>
   <div className="">
   {employee ? (
        <div className="flex flex-col md:flex-row ml-6 mt-10 bg-blue-100 h-auto p-5 rounded-3xl items-center justify-center">
          <div className="text-7xl border h-36 w-36 p-1 rounded-full bg-white flex items-center justify-center">
            {employee.profile_pic ? (
              <img src={employee.profile_pic} alt="" className="rounded-full h-36 w-36" />
            ) : (
              <span className="rounded-full text-gray-600 font-bold text-4xl">
                {employee.first_name.charAt(0)}{employee.last_name.charAt(0)}
              </span>
            )}
          </div>
          <div className="ml-0 md:ml-8 mt-4 flex md:mt-0 text-center md:text-left">
            <div>
              <h1 className="text-2xl font-bold">{employee.first_name} {employee.last_name}</h1>
              <p className="text-gray-600 mb-1 flex justify-center md:justify-start gap-3">
                <strong>Department:</strong> {employee.department}
              </p>
              <p className="text-gray-600 flex justify-center md:justify-start gap-3">
                <strong>Role:</strong> {employee.role}
              </p>
            </div>
            <div className="flex justify-end items-center pl-72">
              <p className="text-gray-600 mb-1 flex justify-center md:justify-start gap-3">
                <strong><IoMdMail color="green" /></strong> {employee.email}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div>Employee not found</div>
      )}
      <ul className="flex gap-16 ml-6 text-lg p-4 bg-blue-300 mt-8 rounded-t-2xl h-12">
        {links.map((linkItem, index) => (
          <li key={index}>
            <NavLink
              to={linkItem.url}
              className={({ isActive }) =>
                isActive ? "text-blue-200 border-t-2 font-bold" : "text-gray-700"
              }
              end
            >
              {linkItem.name}
            </NavLink>
          </li>
        ))}
      </ul>
      <div className="max-w-4xl mx-auto mt-10">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold">Documents</h2>
          <div>
            <input
              type="file"
              onChange={(e) => setSelectedFile(e.target.files[0])}
              accept=".pdf,.doc,.docx,.xls,.xlsx,.txt,.png,.jpg,.jpeg"
              className="hidden"
              id="upload-input"
            />
            <label htmlFor="upload-input" className="px-4 py-2 bg-blue-600 text-white rounded cursor-pointer hover:bg-blue-700 transition">
              Add Document
            </label>
            {selectedFile && (
              <button
                onClick={handleAddDocument}
                className="ml-4 px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700 transition"
              >
                Upload
              </button>
            )}
          </div>
        </div>
        <table className="w-full border-collapse">
          <thead>
            <tr className="bg-gray-100">
              <th className="text-left p-2 border-b border-gray-200">Name</th>
              <th className="text-left p-2 border-b border-gray-200">Type</th>
              <th className="text-left p-2 border-b border-gray-200">Date Created</th>
              <th className="text-left p-2 border-b border-gray-200">Action</th>
            </tr>
          </thead>
          <tbody>
            {documents.length > 0 ? (
              documents.map((document) => (
                <tr key={document.id} className="hover:bg-gray-50">
                  <td className="p-2 border-b border-gray-200">{document.file_name}</td>
                  <td className="p-2 border-b border-gray-200">{document.file_name.split('.').pop()}</td>
                  <td className="p-2 border-b border-gray-200">{new Date(document.created_at).toLocaleString()}</td>
                  <td className="p-2 border-b border-gray-200">
                    <button
                      onClick={() => handleDelete(document.id)}
                      className="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600 transition"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="p-4 text-center text-gray-500">
                  No documents found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
   </div>
    </section>
  );
};

export default Docu;
