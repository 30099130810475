import React from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

const Modal = ({ isOpen, onClose, onSubmit, formData, handleChange }) => {
    if (!isOpen) return null;

    const ukShiftPatterns = [
        'Day Shift (9 AM - 5 PM)',
        'Night Shift (9 PM - 7 AM)',
        'Evening Shift (5 PM - 1 AM)',
        'Early Morning Shift (6 AM - 2 PM)',
        'Split Shift (2 shifts in a day)',
        '12-Hour Shift (7 AM - 7 PM or 7 PM - 7 AM)',
        'Weekend Shift'
    ];

    return (
        <div className="fixed overflow-y-auto inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded shadow-lg w-1/2">
                <h2 className="text-xl font-bold mb-4">Contract and Annual Leave Information</h2>
                <form onSubmit={onSubmit}>
                    <input type="hidden" name="employee_id" value={formData.employee_id} />

                    {/* Entitlement Unit Field */}
                    <Tippy content="Specify the unit for leave entitlement (e.g., days or hours)">
                        <input
                            type="text"
                            name="entitlement_unit"
                            placeholder="Entitlement Unit"
                            value={formData.entitlement_unit}
                            onChange={handleChange}
                            required
                            className="border p-2 mb-2 w-full"
                        />
                    </Tippy>

                    {/* Annual Leave Balance Field */}
                    <Tippy content="Enter the current annual leave balance">
                        <input
                            type="number"
                            name="annual_leave_balance"
                            placeholder="Annual Leave Balance"
                            value={formData.annual_leave_balance}
                            onChange={handleChange}
                            required
                            className="border p-2 mb-2 w-full"
                        />
                    </Tippy>

                    {/* Contract Start Date Field */}
                    <Tippy content="Select the start date of the employee's contract">
                        <input
                            type="date"
                            name="contract_start_date"
                            placeholder="Contract Start Date"
                            value={formData.contract_start_date}
                            onChange={handleChange}
                            required
                            className="border p-2 mb-2 w-full"
                        />
                    </Tippy>

                    {/* Working Time Pattern Field */}
                    <Tippy content="Select the employee's working time pattern">
                        <select
                            name="working_time_pattern"
                            value={formData.working_time_pattern}
                            onChange={handleChange}
                            required
                            className="border p-2 mb-2 w-full"
                        >
                            <option value="">Select Working Time Pattern</option>
                            {ukShiftPatterns.map((pattern, index) => (
                                <option key={index} value={pattern}>
                                    {pattern}
                                </option>
                            ))}
                        </select>
                    </Tippy>

                    {/* Contracted Hours Per Week Field */}
                    <Tippy content="Specify the number of hours contracted per week">
                        <input
                            type="text"
                            name="contracted_hours_per_week"
                            placeholder="Contracted Hours Per Week"
                            value={formData.contracted_hours_per_week}
                            onChange={handleChange}
                            required
                            className="border p-2 mb-2 w-full"
                        />
                    </Tippy>

                    {/* Annual Leave Year Start Field */}
                    <Tippy content="Enter the starting date of the annual leave year">
                        <input
                            type="date"
                            name="annual_leave_year_start"
                            placeholder="Annual Leave Year Start"
                            value={formData.annual_leave_year_start}
                            onChange={handleChange}
                            required
                            className="border p-2 mb-2 w-full"
                        />
                    </Tippy>

                    {/* Min Leave Entitlement Field */}
                    <Tippy content="Specify the minimum leave entitlement">
                        <input
                            type="text"
                            name="min_leave_entitlement"
                            placeholder="Min Leave Entitlement"
                            value={formData.min_leave_entitlement}
                            onChange={handleChange}
                            required
                            className="border p-2 mb-2 w-full"
                        />
                    </Tippy>

                    {/* Working Location Field */}
                    <Tippy content="Enter the working location for the employee">
                        <input
                            type="text"
                            name="working_location"
                            placeholder="Working Location"
                            value={formData.working_location}
                            onChange={handleChange}
                            required
                            className="border p-2 mb-2 w-full"
                        />
                    </Tippy>

                    {/* Address Field */}
                    <Tippy content="Enter the employee's address">
                        <input
                            type="text"
                            name="address"
                            placeholder="Address"
                            value={formData.address}
                            onChange={handleChange}
                            required
                            className="border p-2 mb-2 w-full"
                        />
                    </Tippy>

                    {/* Public Holidays Field */}
                    <Tippy content="Specify the number of public holidays applicable">
                        <input
                            type="text"
                            name="public_holidays"
                            placeholder="Public Holidays"
                            value={formData.public_holidays}
                            onChange={handleChange}
                            required
                            className="border p-2 mb-2 w-full"
                        />
                    </Tippy>

                    <div className="flex justify-between mt-4">
                        <button
                            type="button"
                            onClick={onClose}
                            className="bg-gray-300 text-black p-2 rounded hover:bg-gray-400"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600"
                        >
                            {formData.id ? 'Update Contract' : 'Add Contract'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Modal;
