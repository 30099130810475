import React, { useState, useEffect } from "react";
import axios from "axios";
import {  useParams } from "react-router-dom";
 // Modal component for adding/editing contracts
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import BankModal from "./BankModal";
import { toast } from "react-toastify";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";
const Bank = () => {
  const { id: employeeId } = useParams();
  const [contracts, setContracts] = useState([]);
  const user = JSON.parse(localStorage.getItem('user'))

  const [formData, setFormData] = useState({
    id: null,
    employee_id: employeeId,
   bank_number:"",
   sort_code:""
   
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [contractToDelete, setContractToDelete] = useState(null);

  // Fetch employee details

  // Fetch contracts from the backend
  useEffect(() => {
    const fetchContracts = async () => {
      try {
        const response = await axios.get(`https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/bank/read.php?employee_id=${employeeId}`);
        setContracts(response.data);
      } catch (error) {
        console.error("Error fetching contracts:", error);
      }
    };

    fetchContracts();
  }, [employeeId]);

  // Handle form input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle form submission (add/edit job role)
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (formData.id) {
        // Update existing job role
        const response = await axios.post("https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/bank/update.php", formData);
        console.log(response.data.message);
        toast.success("Successfully updated!", { hideProgressBar: true });
        setContracts(
          contracts.map((contract) =>
            contract.id === formData.id ? { ...contract, ...formData } : contract
          )
        );
      } else {
        // Add new job role
        const response = await axios.post("https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/bank/create.php", formData);
        console.log(response.data.message);
        toast.success("Successfully created!", { hideProgressBar: true });
        setContracts([...contracts, { ...formData, id: response.data.id }]);
      }
      setIsModalOpen(false);
      resetFormData();
    } catch (error) {
      console.error("Error saving job role:", error);
    }
  };

  // Handle contract deletion confirmation modal
  const openDeleteModal = (contractId) => {
    setContractToDelete(contractId);
    setIsDeleteModalOpen(true);
  };

  const confirmDelete = async () => {
    try {
      const response = await axios.post("https://hrmanagement-g3gshyf5hchtc2g9.uksouth-01.azurewebsites.net/bank/delete.php", { contract_id: contractToDelete });
      console.log(response.data.message);
      toast.success("Successfully deleted!", { hideProgressBar: true });
      setContracts(contracts.filter((contract) => contract.id !== contractToDelete));
      setIsDeleteModalOpen(false);
      setContractToDelete(null);
    } catch (error) {
      console.error("Error deleting job role:", error);
    }
  };

  // Close the delete modal
  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setContractToDelete(null);
  };

  // Handle edit action (pre-fill form data)
  const handleEdit = (contract) => {
    setFormData(contract);
    setIsModalOpen(true);
  };

  const resetFormData = () => {
    setFormData({
      id: null,
      employee_id: employeeId,
   bank_number:"",
   sort_code:""
    });
  };
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
          
      
      <div className="">
      <div
        className="flex items-center justify-between p-4 bg-blue-300 rounded-t-md cursor-pointer"
        onClick={toggleExpand}
      >
     <div className="block">
       <h1 className="text-xl font-bold  pl-5 pt-7">Bank details</h1>
        <p className="mb-6 pl-5 w-96 text-base">Employee bank details</p>

     </div>
        {isExpanded ? (
          <FaChevronUp className="text-xl" />
        ) : (
          <FaChevronDown className="text-xl" />
        )}
      </div>
      
      {isExpanded && (
        <div className="p-4 bg-gray-50 rounded-b-md">
          {contracts.length > 0 ? (
            contracts.map((contract) => (
              <div
                key={contract.id}
                className="mb-4 p-4 bg-gray-100 w-full rounded-lg shadow-sm"
              >
                <div className="flex justify-end items-center mb-2">
                {user.role === 'hr' && (
                  <div className="flex space-x-2">
                    <button
                      onClick={() => handleEdit(contract)}
                      className="text-yellow-500 hover:underline"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => openDeleteModal(contract.id)}
                      className="text-red-500 hover:underline"
                    >
                      Delete
                    </button>
                  </div>
                )}
                </div>
                <div className="grid gap-4">
                  <div className="flex">
                    <h3 className="font-medium min-w-[200px]">Job Title:</h3>
                    <p className="flex-1">{contract.bank_number || "N/A"}</p>
                  </div>
                  <div className="flex">
                    <h3 className="font-medium min-w-[200px]">Contract Type:</h3>
                    <p className="flex-1">{contract.sort_code || "N/A"}</p>
                  </div>
             
                 
                </div>
              </div>
            ))
          ) : (
            <p>No Bank information is available for this employee</p>
          )}
{user.role==='hr' && (
          <button
            onClick={() => {
              resetFormData();
              setIsModalOpen(true);
            }}
            className="bg-green-500 text-white p-2 rounded hover:bg-green-600"
          >
            Add Bank Information
          </button>
)}

          {isModalOpen && (
            <BankModal
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              onSubmit={handleSubmit}
              formData={formData}
              handleChange={handleChange}
            />
          )}
        </div>
      )}

      {isDeleteModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75">
          <div className="group select-none w-96 flex flex-col p-4 relative items-center justify-center bg-gray-800 border border-gray-800 shadow-lg rounded-2xl">
            <div className="">
              <div className="text-center p-3 flex-auto justify-center">
                <h2 className="text-xl font-bold py-4 text-gray-200">Are you sure?</h2>
                <p className="font-bold text-sm text-gray-500 px-2">
                  Do you really want to continue? This process cannot be undone.
                </p>
              </div>
              <div className="p-2 mt-2 text-center space-x-4 md:block">
                <button
                  onClick={closeDeleteModal}
                  className="mb-2 md:mb-0 bg-gray-700 px-5 py-2 text-sm shadow-sm font-medium tracking-wider border-2 border-gray-600 text-gray-300 rounded-full hover:bg-gray-800"
                >
                  Cancel
                </button>
                <button
                  onClick={confirmDelete}
                  className="bg-red-500 hover:bg-transparent px-5 ml-2 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:text-red-500 border-2 border-red-500"
                >
                  Confirm Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
    </>
  );
};

export default Bank;
